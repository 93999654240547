import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GameDto } from '../../../types/dto.types';
import { GameStatus } from '../../../types/enums.types';
import { Server } from '../../../server/server';
import Title from '../../../components/title/title.component';
import { Divider, Empty, Flex, List } from 'antd';
import Spinner from '../../../components/spinner/spinner.component';
import EditGameHeader from './edit-game-header/edit-game-header.component';
import EditPendingGame from './edit-pending/edit-pending-game.component';
import EditOpenGame from './edit-open/edit-open-game.component';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const EditGames = () => {
  const { t } = useTranslation('games');
  const [openGlobalGames, setOpenGlobalGames] = useState<GameDto[]>([]);
  const [openGroupGames, setOpenGroupGames] = useState<GameDto[]>([]);
  const [pendingGames, setPendingGames] = useState<GameDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [editingGame, setEditingGame] = useState<{
    id: string;
    status: GameStatus;
  }>({ id: '', status: GameStatus.STATUS_PENDING });

  useEffect(() => {
    setLoading(true);
    const fetchGames = async () => {
      const openGroupResponse = await Server.game.getOpenGames({
        status: GameStatus.STATUS_OPEN_GROUP,
      });
      if (openGroupResponse) {
        setOpenGroupGames(openGroupResponse);
      }

      const openGlobalResponse = await Server.game.getOpenGames({
        status: GameStatus.STATUS_OPEN_GLOBAL,
      });
      if (openGlobalResponse) {
        setOpenGlobalGames(openGlobalResponse);
      }

      const pendingResponse = await Server.game.getPendingGames({});
      if (pendingResponse) {
        setPendingGames(pendingResponse);
      }

      setLoading(false);
    };

    fetchGames();
    setReload(false);
  }, [reload]);

  const renderEditGames = () => {
    if (!editingGame.id) {
      return (
        <>
          {openGlobalGames.length > 0 ? (
            <>
              <Title main={t('edit_game.open_global_title')} />
              <List
                split
                className='edit-game-list'
                dataSource={openGlobalGames}
                renderItem={(item, index) => (
                  <EditGameHeader
                    game={item}
                    key={index}
                    className={
                      index === openGlobalGames.length - 1
                        ? 'edit-game-list-item-last'
                        : 'edit-game-list-item'
                    }
                    setEditingGame={setEditingGame}
                  />
                )}
              />
              <Divider />
            </>
          ) : (
            <></>
          )}
          {openGroupGames.length > 0 ? (
            <>
              <Title main={t('edit_game.open_group_title')} />
              <List
                split
                className='edit-game-list'
                dataSource={openGroupGames}
                renderItem={(item, index) => (
                  <EditGameHeader
                    game={item}
                    key={index}
                    className={
                      index === openGroupGames.length - 1
                        ? 'edit-game-list-item-last'
                        : 'edit-game-list-item'
                    }
                    setEditingGame={setEditingGame}
                  />
                )}
              />
              <Divider />
            </>
          ) : (
            <></>
          )}
          {pendingGames.length > 0 ? (
            <>
              <Title main={t('edit_game.pending_title')} />
              <List
                split
                className='edit-game-list'
                dataSource={pendingGames}
                renderItem={(item, index) => (
                  <EditGameHeader
                    game={item}
                    key={index}
                    className={
                      index === pendingGames.length - 1
                        ? 'edit-game-list-item-last'
                        : 'edit-game-list-item'
                    }
                    setEditingGame={setEditingGame}
                  />
                )}
              />
            </>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      if (editingGame.status === GameStatus.STATUS_PENDING) {
        return (
          <EditPendingGame
            gameId={editingGame.id}
            groupId={pendingGames.find((g) => g.id === editingGame.id)?.groupId}
            setReload={setReload}
            setEditingGame={setEditingGame}
          />
        );
      } else {
        return (
          <EditOpenGame
            gameId={editingGame.id}
            groupId={
              editingGame.status === GameStatus.STATUS_OPEN_GLOBAL
                ? openGlobalGames.find((g) => g.id === editingGame.id)?.groupId
                : openGroupGames.find((g) => g.id === editingGame.id)?.groupId
            }
            reload={reload}
            setReload={setReload}
            setEditingGame={setEditingGame}
          />
        );
      }
    }
  };
  const renderEditGamePage = () => {
    if (
      openGroupGames.length > 0 ||
      openGlobalGames.length > 0 ||
      pendingGames.length > 0
    ) {
      return renderEditGames();
    } else {
      return (
        <Flex
          vertical
          justify='center'
          align='center'
          style={{ height: '400px', width: '100%' }}
        >
          {' '}
          <Empty
            image={<EyeInvisibleOutlined />}
            imageStyle={{ fontSize: '42px' }}
            description={t('edit_game.no_data')}
          />
        </Flex>
      );
    }
  };

  return !loading ? renderEditGamePage() : <Spinner />;
};

export default EditGames;
