import { Context, createContext, useCallback, useMemo, useState } from 'react';
import { UserDto } from '../types/dto.types';
import { Server } from '../server/server';
import i18n from '../i18next';

interface AuthState {
  isLoggedIn: boolean;
  admin: UserDto | null;
  isLoading: boolean;
}

interface AuthMethods {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  refreshAdmin: () => Promise<void>;
  checkIfLoggedIn: () => Promise<void>;
}

const AuthContextPartial: Context<Partial<AuthState & AuthMethods>> =
  createContext({});

export const AuthContext = AuthContextPartial as Context<
  AuthState & AuthMethods
>;

export const AuthProvider = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [admin, setAdmin] = useState<UserDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchAdmin = useCallback(async () => {
    const loggedAdmin = await Server.user.getAdmin();
    if (loggedAdmin != null) {
      setIsLoggedIn(true);
      setAdmin(loggedAdmin);

      i18n.changeLanguage('hr');
    }
    setIsLoading(false);
  }, []);

  const authState = useMemo(
    () => ({
      isLoggedIn,
      admin,
      isLoading,

      login: async (email: string, password: string) => {
        const response = await Server.auth.login(email, password);
        if (response) {
          await fetchAdmin();
        }
      },

      logout: () => {
        localStorage.removeItem('BearerToken');
        localStorage.removeItem('selectedGroupId');
        setIsLoggedIn(false);
        setAdmin(null);
      },

      refreshAdmin: async () => {
        await fetchAdmin();
      },

      checkIfLoggedIn: async () => {
        if (!isLoggedIn && localStorage.getItem('BearerToken') != null) {
          await fetchAdmin();
        }
        setIsLoading(false);
      },
    }),
    [isLoggedIn, admin, isLoading, fetchAdmin]
  );

  return (
    <AuthContext.Provider value={authState}>
      {props.children}
    </AuthContext.Provider>
  );
};
