import { Avatar } from 'antd';

interface Props {
  firstName: string;
  lastName: string;
  size: number;
  className?: string;
}

const Initials = ({ firstName, lastName, size, className }: Props) => {
  return (
    <Avatar className={`${className} initials`} size={size}>
      <span
        style={size === 92 ? { fontSize: '32px' } : {}}
      >{`${firstName.charAt(0)}${lastName.charAt(0)}`}</span>
    </Avatar>
  );
};

export default Initials;
