import { BidGameServer } from './server-subclasses/bid-game.server';
import { GameTypeServer } from './server-subclasses/game-type.server';
import { GameServer } from './server-subclasses/game.server';
import { GroupServer } from './server-subclasses/group.server';
import { LocationServer } from './server-subclasses/location.server';
import { UserGameServer } from './server-subclasses/user-game.server';
import { UserGroupServer } from './server-subclasses/user-group.server';
import { UserLockServer } from './server-subclasses/user-lock.server';
import { UserServer } from './server-subclasses/user.server';
import { VoteMvpServer } from './server-subclasses/vote-mvp.server';
import { AuthServer } from './server-subclasses/auth.server';
import { UserSettingsServer } from './server-subclasses/user-settings.server';
import { RatingServer } from './server-subclasses/rating.server';
import { OpenGameApplicationServer } from './server-subclasses/open-game-application.server';
import { NotificationServer } from './server-subclasses/notification.server';
import { LogServer } from './server-subclasses/log.server';
import { AdminServer } from './server-subclasses/admin.server';

export class Server {
  static admin: AdminServer = new AdminServer();
  static user: UserServer = new UserServer();
  static game: GameServer = new GameServer();
  static group: GroupServer = new GroupServer();
  static voteMvp: VoteMvpServer = new VoteMvpServer();
  static bidGame: BidGameServer = new BidGameServer();
  static userGroup: UserGroupServer = new UserGroupServer();
  static location: LocationServer = new LocationServer();
  static userGame: UserGameServer = new UserGameServer();
  static gameType: GameTypeServer = new GameTypeServer();
  static userLock: UserLockServer = new UserLockServer();
  static auth: AuthServer = new AuthServer();
  static userSettings: UserSettingsServer = new UserSettingsServer();
  static rating: RatingServer = new RatingServer();
  static openGameApplication: OpenGameApplicationServer =
    new OpenGameApplicationServer();
  static notification: NotificationServer = new NotificationServer();
  static log: LogServer = new LogServer();
}
