import { FC } from 'react';
import { PlayerRole, TeamEnum } from '../../types/enums.types';
import { Avatar, Flex, List, Popover, Radio } from 'antd';
import goalkeeper from '../../assets/goalkeeper.png';
import { SelectedPlayerDto } from '../../types/dto.types';
import ProfileCard from '../profile-card/profile-card.component';
import Initials from '../initials/initials.component';

interface SelectedPlayersListProps {
  title: string;
  players: SelectedPlayerDto[];
  team: TeamEnum;
  chooseGoalkeepers: boolean;
  handleSelectGoalkeepers: (playerId: string, team: TeamEnum) => void;
}

const SelectedPlayersList: FC<SelectedPlayersListProps> = (props) => {
  const selectedGoalkeeper = props.players.find(
    (player) =>
      player.team === props.team && player.role === PlayerRole.goalkeeper
  );

  return (
    <>
      <p
        className={
          props.team === TeamEnum.team2 ? `p-0 bold text-end` : `p-0 bold`
        }
        style={{ fontSize: '16px' }}
      >
        {props.title}
      </p>
      <Flex
        gap={6}
        className='selected-players'
        style={
          props.team === TeamEnum.team2 ? { flexDirection: 'row-reverse' } : {}
        }
      >
        <List
          style={{ padding: '0' }}
          dataSource={props.players}
          locale={{
            emptyText: <></>,
          }}
          renderItem={(item, index) => {
            if (item.team === props.team) {
              return (
                <List.Item
                  key={item.id}
                  className={props.team === TeamEnum.team2 ? 'justify-end' : ''}
                >
                  <Popover
                    content={
                      <ProfileCard userId={item.id} recentStats={true} />
                    }
                    trigger={'click'}
                    overlayStyle={{ width: '360px' }}
                    destroyTooltipOnHide={true}
                  >
                    <Flex
                      align='center'
                      gap={12}
                      style={
                        props.team === TeamEnum.team2
                          ? { flexDirection: 'row-reverse' }
                          : {}
                      }
                    >
                      {item.profileImage ? (
                        <Avatar src={item.profileImage} size={32} />
                      ) : (
                        <Initials
                          firstName={item.name.split(' ')[0]}
                          lastName={item.name.split(' ').slice(1).join(' ')}
                          size={32}
                        />
                      )}
                      <Flex gap={4} align='center'>
                        <span>{item.name.split(' ')[0]}</span>
                        <span className='first-letter'>
                          {item.name.split(' ').slice(1).join(' ')}
                        </span>
                      </Flex>
                    </Flex>
                  </Popover>
                </List.Item>
              );
            }
          }}
        />
        {props.chooseGoalkeepers ? (
          <Radio.Group
            value={selectedGoalkeeper ? selectedGoalkeeper.id : null}
          >
            <List
              split={false}
              dataSource={props.players}
              locale={{
                emptyText: <></>,
              }}
              renderItem={(item) => {
                if (item.team === props.team) {
                  return (
                    <List.Item key={item.id}>
                      <Flex>
                        <Radio.Button
                          key={item.id}
                          value={item.id}
                          className={'p-0'}
                          style={{
                            backgroundColor: 'transparent',
                            borderRadius: '50%',
                          }}
                          onClick={(e) =>
                            props.handleSelectGoalkeepers(item.id, props.team)
                          }
                        >
                          <Avatar
                            src={goalkeeper}
                            className='goalkeeper-icon'
                          />
                        </Radio.Button>
                      </Flex>
                    </List.Item>
                  );
                }
              }}
            ></List>
          </Radio.Group>
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
};

export default SelectedPlayersList;
