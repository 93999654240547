import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../context/auth-context';
import { Server } from '../../../../server/server';
import { Col, Flex, Form, Input, Row, Tooltip, notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ButtonComponent from '../../../../components/buttons/button.component';
import SelectGroup from '../../../../components/select-group/select-group.component';

const AddNewUser = () => {
  const { t } = useTranslation('users');
  const { admin } = useContext(AuthContext);
  const [newUserData, setNewUserData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
  }>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  const submitNewUser = async () => {
    if (selectedGroupId && admin) {
      const sendInviteData = {
        ...newUserData,
        groupId: selectedGroupId,
        adminFirstName: admin.firstName,
        adminLastName: admin.lastName,
      };
      try {
        await Server.user.sendInvite(sendInviteData);
        setNewUserData({ firstName: '', lastName: '', email: '' });
        notification.success({ message: t('new_user.create.success') });
      } catch (error) {}
    }
  };

  const handleFormValuesChange = (changedValues: any) => {
    setNewUserData((prevNewUserData) => ({
      ...prevNewUserData,
      ...changedValues,
    }));
  };
  return (
    <>
      <Flex gap={'12px'} justify='center'>
        <h3>{t('new_user.create.title').toUpperCase()}</h3>
        <Tooltip title={t('new_user.create.tip')} placement='right'>
          <InfoCircleOutlined />
        </Tooltip>
      </Flex>
      <Row justify='center'>
        <Col xs={24} sm={24} md={12} xl={12} xxl={6}>
          <SelectGroup
            value={selectedGroupId ? selectedGroupId : null}
            className='w-100'
            onChange={(value) => setSelectedGroupId(value)}
          />
        </Col>
      </Row>
      <Row justify='center' className='mt-2'>
        <Col xs={24} sm={24} md={12} xl={12} xxl={6}>
          <Form
            name='addNewUser'
            onFinish={submitNewUser}
            onValuesChange={handleFormValuesChange}
          >
            <Form.Item
              name='firstName'
              rules={[
                { required: true, message: t('new_user.create.required') },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                size='large'
                className='signup-input mt-3'
                placeholder={t('new_user.create.firstName')}
              />
            </Form.Item>
            <Form.Item
              name='lastName'
              rules={[
                { required: true, message: t('new_user.create.required') },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                size='large'
                className='signup-input mt-3'
                placeholder={t('new_user.create.lastName')}
              />
            </Form.Item>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: t('new_user.create.required') },
                {
                  type: 'email',
                  message: t('new_user.create.email_invalid'),
                },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                size='large'
                className='signup-input mt-3'
                placeholder={t('new_user.create.email')}
              />
            </Form.Item>
            <Form.Item>
              <ButtonComponent
                disabled={!selectedGroupId}
                className='primary-button'
                style={{ width: '100%' }}
                text={t('new_user.submit')}
                htmlType='submit'
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddNewUser;
