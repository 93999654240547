import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Groups from './groups/groups.component';
import NewGroup from './new-group/new-group.component';
import { useState } from 'react';

const GroupsPanel = () => {
  const { t } = useTranslation('groups');
  const [activeTab, setActiveTab] = useState<string>('1');
  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('tabs.groups').toUpperCase(),
      children: <Groups />,
    },
    {
      key: '2',
      label: t('tabs.new_group').toUpperCase(),
      children: <NewGroup setActiveTab={setActiveTab} />,
    },
  ];
  return (
    <Tabs
      onTabClick={(key) => setActiveTab(key)}
      activeKey={activeTab}
      items={tabItems}
      destroyInactiveTabPane={true}
    ></Tabs>
  );
};

export default GroupsPanel;
