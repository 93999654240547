import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import GameTypes from './game-types/game-types.component';
import NewGameType from './new-game-type/new-game-type.component';
import { useState } from 'react';

const GameTypePanel = () => {
  const { t } = useTranslation('game-types');
  const [activeTab, setActiveTab] = useState<string>('1');
  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('tabs.game-types').toUpperCase(),
      children: <GameTypes />,
    },
    {
      key: '2',
      label: t('tabs.new_game-type').toUpperCase(),
      children: <NewGameType setActiveTab={setActiveTab} />,
    },
  ];
  return (
    <Tabs
      onTabClick={(key) => setActiveTab(key)}
      activeKey={activeTab}
      items={tabItems}
      destroyInactiveTabPane={true}
    ></Tabs>
  );
};

export default GameTypePanel;
