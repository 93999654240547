import {
  BarChartData,
  CreateNewGameDto,
  EditGameDto,
  GameDto,
  GamesCompareFiltersDto,
  GamesWithPageDto,
  GetGamesDto,
  GetGamesWithFilters,
  VotingGameDto,
} from '../../types/dto.types';
import { GameStatus } from '../../types/enums.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class GameServer {
  private readonly basePath = `${serverUrl}admin/game`;

  // GET ALL GAMES
  async getAllGames(
    getAllGames: GetGamesDto
  ): Promise<{ games: GameDto[]; count: number }> {
    const params = new URLSearchParams();
    params.append('status', getAllGames.status.toString());
    params.append('take', getAllGames.pagination.take.toString());
    params.append('offset', getAllGames.pagination.offset.toString());

    if (getAllGames.relations) {
      params.append('relations', getAllGames.relations.toString());
    }
    if (getAllGames.groupId) {
      params.append('groupId', getAllGames.groupId.toString());
    }

    const response = await axios({
      method: 'GET',
      url: this.basePath,
      responseType: 'json',
      params: params,
    });

    return response.data as { games: GameDto[]; count: number };
  }

  // GET GAME BY ID
  async getGameById(id: string): Promise<GameDto> {
    const params = new URLSearchParams();
    params.append('id', id.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/game-by-id`,
      responseType: 'json',
      params: params,
    });

    return response.data as GameDto;
  }

  // GET GAMES WITH FILTERS (DATE, TYPE, PLAYERS)
  async getGamesWithFilter(
    filters: GetGamesWithFilters
  ): Promise<GamesWithPageDto> {
    const params = new URLSearchParams();
    params.append('take', filters.pagination.take.toString());
    params.append('offset', filters.pagination.offset.toString());
    if (filters.groupId) {
      params.append('groupId', filters.groupId.toString());
    }
    if (filters.date) {
      params.append('date', filters.date.toString());
    }
    if (filters.dateTo) {
      params.append('dateTo', filters.dateTo.toString());
    }
    if (filters.gameTypeId) {
      params.append('gameTypeId', filters.gameTypeId.toString());
    }

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/game-filters`,
      responseType: 'json',
      params: params,
    });

    return response.data as GamesWithPageDto;
  }

  // CREATE GAME
  async createGame(newGame: CreateNewGameDto) {
    const response = await axios({
      method: 'POST',
      url: this.basePath,
      responseType: 'json',
      data: newGame,
    });

    return response.data;
  }

  // EDIT GAME
  async editGame(editedGame: EditGameDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/edit-game`,
      responseType: 'json',
      data: editedGame,
    });

    return response.data;
  }

  // DELETE GAME
  async deleteGame(id: string) {
    const params = new URLSearchParams();
    params.append('id', id.toString());

    const response = await axios({
      method: 'DELETE',
      url: `${this.basePath}/delete`,
      responseType: 'json',
      params: params,
    });

    return response.data;
  }

  // GET GAMES FOR COMPARISON
  async getGamesForComparison(
    filters: GamesCompareFiltersDto
  ): Promise<GamesWithPageDto> {
    const response = await axios({
      method: 'POST',
      url: `${this.basePath}/comparison`,
      responseType: 'json',
      data: filters,
    });

    return response.data as GamesWithPageDto;
  }

  // GET PENDING GAMES
  async getPendingGames(getPendingGamesDto: {
    groupId?: string;
    userId?: string;
  }): Promise<GameDto[]> {
    const params = new URLSearchParams();
    getPendingGamesDto.groupId &&
      params.append('groupId', getPendingGamesDto.groupId.toString());
    getPendingGamesDto.userId &&
      params.append('userId', getPendingGamesDto.userId.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/pending`,
      responseType: 'json',
      params: params,
    });

    return response.data as GameDto[];
  }

  // GET LAST GAMES
  async getLastGames(getLastGamesDto: {
    groupId?: string;
    userId?: string;
  }): Promise<GameDto[]> {
    const params = new URLSearchParams();
    getLastGamesDto.groupId &&
      params.append('groupId', getLastGamesDto.groupId.toString());
    getLastGamesDto.userId &&
      params.append('userId', getLastGamesDto.userId.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/last`,
      responseType: 'json',
      params: params,
    });

    return response.data as GameDto[];
  }

  // GET VOTING GAMES
  async getVotingGames(getVotingGamesDto: {
    groupId?: string;
    userId?: string;
  }): Promise<VotingGameDto[]> {
    const params = new URLSearchParams();
    getVotingGamesDto.groupId &&
      params.append('groupId', getVotingGamesDto.groupId.toString());
    getVotingGamesDto.userId &&
      params.append('userId', getVotingGamesDto.userId.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/voting`,
      responseType: 'json',
      params: params,
    });

    return response.data as VotingGameDto[];
  }

  // GET PUBLISHED GAMES
  async getOpenGames(getOpenGamesDto: {
    status?: GameStatus;
    groupId?: string;
    gameTypeId?: string;
    date?: string;
    userId?: string;
  }): Promise<GameDto[]> {
    const params = new URLSearchParams();

    getOpenGamesDto.status &&
      params.append('status', getOpenGamesDto.status.toString());
    getOpenGamesDto.groupId &&
      params.append('groupId', getOpenGamesDto.groupId.toString());
    getOpenGamesDto.gameTypeId &&
      params.append('gameTypeId', getOpenGamesDto.gameTypeId.toString());
    getOpenGamesDto.date &&
      params.append('date', getOpenGamesDto.date.toString());
    getOpenGamesDto.userId &&
      params.append('userId', getOpenGamesDto.userId.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/open`,
      responseType: 'json',
      params: params,
    });
    return response.data as GameDto[];
  }

  // GET ACTIVITY FOR GROUP
  async getActivityForGroup(groupId: string): Promise<BarChartData[]> {
    const params = new URLSearchParams();
    params.append('groupId', groupId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/group-activity`,
      responseType: 'json',
      params: params,
    });

    return response.data as BarChartData[];
  }
}
