import { Flex } from 'antd';
import { FC } from 'react';

interface TitleProps {
  main?: string;
  desc1?: string;
  desc2?: string;
}

const Title: FC<TitleProps> = (props) => {
  return (
    <Flex vertical>
      <h3 className='m-0'>{props.main}</h3>
      <p className='m-0 text-uppercase gray-color'>{props.desc1}</p>
      <p className='m-0 text-uppercase gray-color'>{props.desc2}</p>
    </Flex>
  );
};

export default Title;
