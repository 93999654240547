import { Divider } from 'antd';
import AddExistingUser from './add-existing-user/add-existing-user.component';
import AddNewUser from './add-new-user/add-new-user.component';

const NewUser = () => {
  return (
    <div>
      <AddNewUser />
      <Divider />
      <AddExistingUser />
    </div>
  );
};

export default NewUser;
