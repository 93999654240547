import { LogSource, LogStatus, LogType } from '../../types/enums.types';

export const getTagClassName = (tag: LogStatus | LogSource | LogType) => {
  switch (tag) {
    case LogStatus.ERROR:
      return 'error-tag';
    case LogStatus.SUCCESS:
      return 'success-tag';
    case LogSource.ADMIN:
      return 'admin-tag';
    case LogSource.USER:
      return 'user-tag';
    case LogType.CREATE:
      return 'create-tag';
    case LogType.UPDATE:
      return 'update-tag';
    case LogType.DELETE:
      return 'delete-tag';
    default:
      break;
  }
};
