import { Avatar, Dropdown, Flex, MenuProps } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import { useTranslation } from 'react-i18next';

const HeaderComponent = () => {
  const { logout, admin } = useContext(AuthContext);
  const { t } = useTranslation('header');

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className='text-uppercase' style={{ color: 'red' }}>
          {t('menu.logout')}
        </div>
      ),
      onClick: () => {
        logout();
      },
    },
  ];

  return (
    <Header style={{ backgroundColor: '#00000028' }}>
      <div className='d-flex space-between align-items-center'>
        <div className='d-flex space-between align-items-center'>
          <Link to='/dashboard'>
            <span className='logo-temp m-0'>
              Balunko <span className='logo-temp-small m-0'>admin</span>
            </span>
          </Link>
        </div>
        <div>
          <Flex justify='space-between' align='center' gap={12}>
            {/* <Notifications /> */}
            <Dropdown menu={{ items }} trigger={['click']} destroyPopupOnHide>
              <Avatar
                className='header-avatar'
                src={
                  admin?.profileImage ?? (
                    <span>{`${admin?.firstName.charAt(
                      0
                    )}${admin?.lastName.charAt(0)}`}</span>
                  )
                }
                size={32}
              />
            </Dropdown>
          </Flex>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;
