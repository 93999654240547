import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Server } from '../../../../server/server';
import { SelectOptionsProps } from '../../../../types/props.types';
import { Card, Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  setSelectedGroupId: Dispatch<SetStateAction<string>>;
}

const SelectNewGameGroup = (props: Props) => {
  const { t } = useTranslation('games');
  const [groupOptions, setGroupOptions] = useState<SelectOptionsProps[]>([]);
  useEffect(() => {
    const fetchGroups = async () => {
      const groupsResponse = await Server.group.getAllGroups();
      const groupOptions = groupsResponse?.map((group) => ({
        value: group.id,
        label: group.name,
      }));

      setGroupOptions(groupOptions);
    };

    fetchGroups();
  }, []);

  return (
    <Row justify='center'>
      <Col xl={8} lg={12} md={16} sm={24} xs={24}>
        <Card className='card-main'>
          <p>{t('new_game.group_select')}</p>
          <Select
            options={groupOptions}
            className='w-100'
            placeholder={t('common:group')}
            onChange={(value) => props.setSelectedGroupId(value)}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default SelectNewGameGroup;
