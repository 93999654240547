import { Dispatch, FC, SetStateAction, useState } from 'react';
import { UpdateUserInfoDto, UserDto } from '../../../../types/dto.types';
import { Server } from '../../../../server/server';
import { DatePicker, Input, Select, Form, Modal, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

interface EditInfoProps {
  user: UserDto;
  isInfoModalOpen: boolean;
  setIsInfoModalOpen: Dispatch<SetStateAction<boolean>>;
}

const EditInfo: FC<EditInfoProps> = (props) => {
  const { user, isInfoModalOpen, setIsInfoModalOpen } = props;
  const { t } = useTranslation('profile');
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UpdateUserInfoDto>({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    height: user.height,
    weight: user.weight,
    adress: user.adress,
    city: user.city,
    country: user.country,
    phone: user.phone,
    dateOfBirth: user.dateOfBirth ? dayjs(user?.dateOfBirth) : '',
    gender: user.gender ? t(`user_info.${user.gender}`) : '',
  });

  const handleFormValuesChange = (changedValues: any) => {
    if ('dateOfBirth' in changedValues) {
      if (changedValues.dateOfBirth) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          dateOfBirth: changedValues.dateOfBirth.format('YYYY-MM-DD'),
        }));
      }
    } else {
      setUserData((prevUserData) => ({
        ...prevUserData,
        ...changedValues,
      }));
    }
  };

  const submitChanges = async () => {
    try {
      setLoading(true);
      await Server.user.updateInfo(userData);
    } catch (err) {
      setLoading(false);
      return err;
    }
    setLoading(false);
    setIsInfoModalOpen(false);
  };

  return (
    <Modal
      confirmLoading={loading}
      open={isInfoModalOpen}
      onOk={() => submitChanges()}
      onCancel={() => setIsInfoModalOpen(false)}
      okText={t('common:confirm')}
      cancelText={t('common:cancel')}
    >
      <Form
        name='change-info'
        className='change-info-form'
        onValuesChange={handleFormValuesChange}
        layout='vertical'
        initialValues={userData}
      >
        <Form.Item name='dateOfBirth' label={t('user_info.date_of_birth')}>
          <DatePicker
            inputReadOnly
            size='large'
            className='signup-input mt-3'
            format={'DD.MM.YYYY.'}
          />
        </Form.Item>

        <Form.Item name='firstName' label={t('user_info.first_name')}>
          <Input size='large' className='signup-input mt-3' />
        </Form.Item>

        <Form.Item name='lastName' label={t('user_info.last_name')}>
          <Input size='large' className='signup-input mt-3' />
        </Form.Item>

        <Form.Item name='gender' label={t('user_info.gender')}>
          <Select
            size='large'
            className='signup-input mt-3'
            options={[
              { value: 'Male', label: t('user_info.Male') },
              { value: 'Female', label: t('user_info.Female') },
            ]}
            style={{ textAlign: 'start' }}
          />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name='height'
              label={t('user_info.height')}
              rules={[
                {
                  pattern: /^[0-9]*$/,
                  message: t('user_info.numeric'),
                },
              ]}
            >
              <Input className='w-100' suffix={'cm'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='weight'
              label={t('user_info.weight')}
              rules={[
                {
                  pattern: /^[0-9]*$/,
                  message: t('user_info.numeric'),
                },
              ]}
            >
              <Input className='w-100' suffix={'kg'} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name='adress' label={t('user_info.address')}>
          <Input size='large' className='signup-input mt-3' />
        </Form.Item>
        <Form.Item name='city' label={t('user_info.city')}>
          <Input size='large' className='signup-input mt-3' />
        </Form.Item>
        <Form.Item name='country' label={t('user_info.country')}>
          <Input size='large' className='signup-input mt-3' />
        </Form.Item>
        <Form.Item name='phone' label={t('user_info.phone')}>
          <Input size='large' className='signup-input mt-3' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditInfo;
