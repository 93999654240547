import { Avatar, Card, Col, Flex, List, Row, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { UserDto } from '../../../types/dto.types';
import {
  CalendarOutlined,
  ColumnHeightOutlined,
  EditOutlined,
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  ManOutlined,
  PhoneOutlined,
  UserOutlined,
  WomanOutlined,
} from '@ant-design/icons';
import EditInfo from './edit-info/edit-info.component';
import EditCredentials from './edit-credentials/edit-credentials.component';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/title/title.component';
import dayjs from 'dayjs';
import { Server } from '../../../server/server';

interface GeneralInfoProps {
  userId: string;
  loading: boolean;
}

const GeneralInfo = (props: GeneralInfoProps) => {
  const { t } = useTranslation('profile');
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isCredModalOpen, setIsCredModalOpen] = useState(false);
  const [user, setUser] = useState<UserDto>();

  useEffect(() => {
    const fetchUser = async () => {
      const response = await Server.user.getUserById(props.userId);
      setUser(response);
    };

    fetchUser();
  }, [props.userId]);

  let dateOfBirth;
  if (user?.dateOfBirth) {
    dateOfBirth = dayjs(user.dateOfBirth).format('DD.MM.YYYY.');
  }

  return user ? (
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <Card
        className='card-cta-main h-100 profile-info-card'
        style={{ overflow: 'auto', height: '446px' }}
      >
        <Row>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Flex gap={12}>
              <Title main={t('user_info.title')} />
              <EditOutlined onClick={() => setIsInfoModalOpen(true)} />
            </Flex>
            <List size='small' className='mt-1'>
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  title={
                    !props.loading ? (
                      user?.firstName + ' ' + user?.lastName
                    ) : (
                      <Skeleton.Input active size='small' />
                    )
                  }
                  description={t('user_info.full_name')}
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<CalendarOutlined />} />}
                  title={
                    !props.loading ? (
                      dateOfBirth ? (
                        <span>{dateOfBirth}</span>
                      ) : (
                        ''
                      )
                    ) : (
                      <Skeleton.Input active size='small' />
                    )
                  }
                  description={t('user_info.date_of_birth')}
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      icon={
                        user?.gender === 'Male' ? (
                          <ManOutlined />
                        ) : (
                          <WomanOutlined />
                        )
                      }
                    />
                  }
                  title={
                    !props.loading ? (
                      user?.gender ? (
                        t(`user_info.${user.gender}`)
                      ) : (
                        ''
                      )
                    ) : (
                      <Skeleton.Input active size='small' />
                    )
                  }
                  description={t('user_info.gender')}
                />
              </List.Item>
              {user.height && user.weight ? (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<ColumnHeightOutlined />} />}
                    title={`${user.height} cm, ${user.weight} kg`}
                    description={t('user_info.height_weight')}
                  />
                </List.Item>
              ) : (
                <></>
              )}
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<HomeOutlined />} />}
                  title={
                    !props.loading ? (
                      <>
                        {user?.adress ? `${user.adress}, ` : ''}
                        {user?.city ? `${user.city}, ` : ''}
                        {user?.country ? `${user.country}` : ''}
                      </>
                    ) : (
                      <Skeleton.Input active size='small' />
                    )
                  }
                  description={t('user_info.address')}
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<PhoneOutlined />} />}
                  title={
                    !props.loading ? (
                      user?.phone ?? ''
                    ) : (
                      <Skeleton.Input active size='small' />
                    )
                  }
                  description={t('user_info.phone')}
                />
              </List.Item>
            </List>
          </Col>
        </Row>
        <Row className='mt-1'>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Flex gap={12}>
              <Title main={t('credentials.title')} />
              <EditOutlined onClick={() => setIsCredModalOpen(true)} />
            </Flex>
            <List size='small' className='mt-1'>
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<MailOutlined />} />}
                  title={
                    !props.loading ? (
                      user?.email
                    ) : (
                      <Skeleton.Input active size='small' />
                    )
                  }
                  description={t('credentials.email')}
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<LockOutlined />} />}
                  title={
                    !props.loading ? (
                      '********'
                    ) : (
                      <Skeleton.Input active size='small' />
                    )
                  }
                  description={t('credentials.password')}
                />
              </List.Item>
            </List>
          </Col>
        </Row>
      </Card>
      <EditInfo
        user={user!}
        isInfoModalOpen={isInfoModalOpen}
        setIsInfoModalOpen={setIsInfoModalOpen}
      />
      <EditCredentials
        user={user!}
        isCredModalOpen={isCredModalOpen}
        setIsCredModalOpen={setIsCredModalOpen}
      />
    </Col>
  ) : (
    <></>
  );
};

export default GeneralInfo;
