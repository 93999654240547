import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { GameDto, SelectedPlayerDto } from '../../../../types/dto.types';
import { SelectOptionsProps } from '../../../../types/props.types';
import {
  GameState,
  GameStatus,
  PlayerRole,
  TeamEnum,
  UserGameAction,
} from '../../../../types/enums.types';
import { Server } from '../../../../server/server';
import { Card, Divider, Flex, Popconfirm, notification } from 'antd';
import ButtonComponent from '../../../../components/buttons/button.component';
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { checkIfGameIsFinished } from '../../../../utilities/data/check-if-game-is-finished';
import EditDateLocation from '../edit-date-location/edit-date-location.component';
import EditPlayers from '../edit-players/edit-players.component';
import EnterGameResult from '../../../../components/enter-game-result/enter-game-result.component';
import PublishingOptions from '../../new-game/publishing-options/publishing-options.component';
import NewGameOptions from '../../new-game/new-game-options/new-game-options.component';
import dayjs from 'dayjs';

interface EditPendingGameProps {
  gameId: string;
  groupId?: string;
  setReload: Dispatch<SetStateAction<boolean>>;
  setEditingGame: Dispatch<
    SetStateAction<{
      id: string;
      status: GameStatus;
    }>
  >;
}

interface ChangedUserGames {
  gameId: string;
  userId: string;
  action: UserGameAction;
  state?: GameState;
  team?: TeamEnum;
  role?: PlayerRole;
}

const EditPendingGame: FC<EditPendingGameProps> = (props) => {
  const { t } = useTranslation(['games', 'players']);
  const [options, setOptions] = useState<{
    users: SelectOptionsProps[] | null;
    locations: SelectOptionsProps[] | null;
  }>();
  const [game, setGame] = useState<GameDto>();
  const [players, setPlayers] = useState<SelectedPlayerDto[]>();
  const [userGames, setUserGames] = useState<ChangedUserGames[]>([]);
  const [mvpVotingStatus, setMvpVotingStatus] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');
  const [locationId, setLocationId] = useState<string>('');
  const [gameResult, setGameResult] = useState<{
    team1: number | undefined;
    team2: number | undefined;
  }>({
    team1: undefined,
    team2: undefined,
  });
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openGameInfo, setOpenGameInfo] = useState({
    players: 0,
    goalkeepers: 0,
  });
  const [openLevel, setOpenLevel] = useState<GameStatus | null>();

  const playersTeam1 = players?.filter(
    (player) => player.team === TeamEnum.team1
  );
  const playersTeam2 = players?.filter(
    (player) => player.team === TeamEnum.team2
  );

  useEffect(() => {
    const fetchGame = async () => {
      const gameResponse = await Server.game.getGameById(props.gameId);
      setGame(gameResponse);

      const players = gameResponse.userGames.map((userGame) => {
        return {
          name: userGame.firstName + ' ' + userGame.lastName,
          id: userGame.userId,
          team: userGame.team,
          role: userGame.role,
        };
      });

      setPlayers(players);
      setMvpVotingStatus(gameResponse.statusVoting);
    };
    const fetchOptions = async () => {
      const users = await Server.user.getAllUsers({
        groupId: props.groupId,
      });
      const locations = await Server.location.getLocations({});

      const userOptions = users?.users.map((user) => {
        return {
          value: user.id,
          label: user.firstName + ' ' + user.lastName,
          userLocks: user.userLocks,
        };
      });

      const locationOptions = locations?.locations.map((location) => {
        return { value: location.id, label: location.name };
      });

      setOptions({
        users: userOptions ?? null,
        locations: locationOptions ?? null,
      });
    };

    fetchGame();
    fetchOptions();
  }, [props.gameId, props.groupId]);

  const handleSelectGoalkeepers = (playerId: string, team: TeamEnum) => {
    const updatedPlayers = players?.map((player) => {
      if (player.id === playerId && player.role === PlayerRole.player) {
        return { ...player, role: PlayerRole.goalkeeper };
      } else if (player.team === team) {
        return { ...player, role: PlayerRole.player };
      } else return player;
    });
    setPlayers(updatedPlayers);

    const updatedUserGames = userGames.map((userGame) => {
      if (userGame.userId === playerId) {
        return { ...userGame, role: PlayerRole.goalkeeper };
      } else if (userGame.team === team) {
        return { ...userGame, role: PlayerRole.player };
      } else return userGame;
    });
    setUserGames(updatedUserGames);
  };

  const userGameHandler = (
    userId: string,
    action: UserGameAction,
    playerTeam?: TeamEnum,
    playerRole?: PlayerRole
  ) => {
    const userGameRaw: ChangedUserGames = {
      userId: userId,
      gameId: props.gameId,
      team: playerTeam,
      role: playerRole,
      action: action,
    };

    setUserGames([...userGames, userGameRaw]);
  };

  const removePlayer = (userId: string) => {
    userGameHandler(userId, UserGameAction.DELETE);
    const newTeam = players?.filter((player) => player.id !== userId);
    setPlayers(newTeam);
  };

  const handleDeleteGame = async () => {
    try {
      await Server.game.deleteGame(props.gameId);
      props.setReload(true);
      notification.success({
        message: t('edit_game.notifications.success_delete'),
      });
      props.setEditingGame({ id: '', status: GameStatus.STATUS_PENDING });
    } catch (error) {}
  };

  const handleSelectPlayers = (
    selectedOption: SelectOptionsProps,
    team: TeamEnum
  ) => {
    let allTeamIds;
    if (team === TeamEnum.team1) {
      allTeamIds = playersTeam1?.map((player) => player.id);
    } else {
      allTeamIds = playersTeam2?.map((player) => player.id);
    }

    if (!allTeamIds?.includes(selectedOption.value)) {
      const option = {
        id: selectedOption.value,
        name: selectedOption.label,
        team: team,
        role: PlayerRole.player,
      };

      players && setPlayers([...players, option]);
      userGameHandler(
        selectedOption.value,
        UserGameAction.CREATE,
        team,
        option.role
      );
    } else {
      const newSelectedPlayers = players?.filter(
        (player) => player.id !== selectedOption.value
      );
      removePlayer(selectedOption.value);
      setPlayers(newSelectedPlayers);
    }
  };

  const handleEditGame = async () => {
    const currentUserGames = players?.map((player) => {
      return {
        userId: player.id,
        gameId: props.gameId,
        team: player.team,
        role: player.role,
      };
    });

    let newStatus: GameStatus;
    if (gameResult.team1 && gameResult.team2) {
      newStatus = GameStatus.STATUS_FINISHED;
    } else if (openStatus && openLevel) {
      newStatus = openLevel;
    } else {
      newStatus = GameStatus.STATUS_PENDING;
    }

    const pendingGameEditData = {
      id: props.gameId,
      date: date !== '' ? date : game?.date,
      locationId: locationId !== '' ? locationId : game?.location.id,
      userGames: userGames,
      team1Goals: gameResult?.team1,
      team2Goals: gameResult?.team2,
      isGameFinished: checkIfGameIsFinished(gameResult),
      groupId: game?.groupId,
      status: newStatus,
      statusVoting: mvpVotingStatus ?? false,
      openGameInfo: openGameInfo ?? null,
    };

    try {
      await Server.game.editGame(pendingGameEditData);
      currentUserGames?.map(
        async (userGame) => await Server.userGame.updateUserGame(userGame)
      );
      props.setReload(true);
      notification.success({
        message: t('edit_game.notifications.success_edit'),
      });
      props.setEditingGame({ id: '', status: GameStatus.STATUS_PENDING });
    } catch (error) {}
  };

  return (
    <>
      <ButtonComponent
        className='secondary-button'
        icon={<ArrowLeftOutlined />}
        text={t('common:back')}
        onClick={() =>
          props.setEditingGame({ id: '', status: GameStatus.STATUS_PENDING })
        }
      />
      <Card className='card-cta-main p-0 mt-1'>
        <Flex vertical gap={'10px'}>
          <Flex justify='space-between' align='center'>
            <h3>{t(`common:game_types.${game?.type}`)}</h3>
            <Popconfirm
              title={t('edit_game.delete_title')}
              description={t('edit_game.delete_desc')}
              overlayStyle={{
                backgroundColor: 'var(--dark-gray)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                borderRadius: '8px',
              }}
              onConfirm={() => handleDeleteGame()}
              onCancel={() => {}}
              okText={t('common:confirm')}
              cancelText={t('common:cancel')}
            >
              <ButtonComponent
                className='danger-button'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Flex>
          {game ? (
            <EditDateLocation
              game={game}
              setDate={setDate}
              setLocationId={setLocationId}
              locations={options?.locations}
            />
          ) : (
            <></>
          )}
          {playersTeam1 && playersTeam2 && players && (
            <EditPlayers
              groupId={props.groupId!}
              players={players}
              playersTeam1={playersTeam1}
              playersTeam2={playersTeam2}
              users={options?.users}
              handleSelectPlayers={handleSelectPlayers}
              handleSelectGoalkeepers={handleSelectGoalkeepers}
            />
          )}
          {dayjs(game?.date) <= dayjs() ? (
            <EnterGameResult setGameResult={setGameResult} />
          ) : (
            <>
              <Divider />
              <Flex
                justify='space-between'
                className='new-game-options-container'
              >
                <NewGameOptions
                  mvpVotingStatus={mvpVotingStatus}
                  setMvpVotingStatus={setMvpVotingStatus}
                />
                <PublishingOptions
                  openGameInfo={openGameInfo}
                  setOpenGameInfo={setOpenGameInfo}
                  openLevel={openLevel}
                  setOpenLevel={setOpenLevel}
                  openGameStatus={openStatus}
                  setOpenGameStatus={setOpenStatus}
                />
              </Flex>
            </>
          )}

          <Flex justify='end' align='end'>
            <ButtonComponent
              disabled={openStatus && !openLevel}
              className='primary-button'
              text={t('common:confirm')}
              onClick={() => handleEditGame()}
            />
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export default EditPendingGame;
