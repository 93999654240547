import { Col, Flex, Input, Row, notification } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import Title from '../../../components/title/title.component';
import { useTranslation } from 'react-i18next';
import Map from '../locations/location-body/map/map.component';
import ButtonComponent from '../../../components/buttons/button.component';
import { Server } from '../../../server/server';

interface Props {
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const SPLIT_POSITION = { lat: 43.5081, lng: 16.4402 };

const NewLocation = (props: Props) => {
  const { t } = useTranslation('locations');
  const [newLocation, setNewLocation] = useState<{
    name: string;
    address: string;
  }>({
    name: '',
    address: '',
  });
  const [coordinates, setCoordinates] = useState<number[]>([0, 0]);

  const createLocation = async () => {
    try {
      await Server.location.createLocation({
        ...newLocation,
        latitude: coordinates[0],
        longitude: coordinates[1],
      });
      notification.success({ message: t('new_location.success') });
      props.setActiveTab('1');
    } catch (error) {}
  };

  return (
    <Row justify='center' className='mt-1'>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Flex justify='center'>
          <Title main={t('new_location.title').toUpperCase()} />
        </Flex>
        <Row className='mt-1'>
          <Input
            placeholder={t('name')}
            autoFocus
            onChange={(e) =>
              setNewLocation((prevData) => ({
                ...prevData,
                name: e.target.value,
              }))
            }
          />
        </Row>
        <Row className='mt-1'>
          <Input
            placeholder={t('address')}
            autoFocus
            onChange={(e) =>
              setNewLocation((prevData) => ({
                ...prevData,
                address: e.target.value,
              }))
            }
          />
        </Row>
        <Map
          position={SPLIT_POSITION}
          formDisabled={false}
          setCoordinates={setCoordinates}
        />{' '}
        <Flex justify='end'>
          <ButtonComponent
            disabled={
              !newLocation.name || !newLocation.address || coordinates[0] === 0
            }
            className='primary-button mt-2 '
            text={t('common:confirm')}
            onClick={createLocation}
          />
        </Flex>
      </Col>
    </Row>
  );
};

export default NewLocation;
