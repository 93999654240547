import {
  BarsOutlined,
  DribbbleOutlined,
  EnvironmentOutlined,
  PlayCircleOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import GamesPanel from '../../pages/games-panel/games-panel.component';
import GroupsPanel from '../../pages/groups-panel/groups-panel.component';
import GameTypePanel from '../../pages/game-type-panel/game-type-panel.component';
import LocationsPanel from '../../pages/locations-panel/locations-panel.component';
import UsersPanel from '../../pages/users-panel/users-panel.component';
import ActivityLog from '../../pages/activity-log/activity-log.component';

export const RoutesDefinitions = [
  {
    path: '/games',
    element: <GamesPanel />,
  },
  {
    path: '/users',
    element: <UsersPanel />,
  },
  {
    path: '/groups',
    element: <GroupsPanel />,
  },
  {
    path: '/game-types',
    element: <GameTypePanel />,
  },
  {
    path: '/locations',
    element: <LocationsPanel />,
  },
  {
    path: '/activity-log',
    element: <ActivityLog />,
  },
];

export const SidebarLinks = [
  {
    title: 'games',
    path: '/games',
    icon: <PlayCircleOutlined />,
    element: <GamesPanel />,
    isMainMenu: true,
  },
  {
    title: 'users',
    path: '/users',
    icon: <UserOutlined />,
    element: <UsersPanel />,
    isMainMenu: true,
  },
  {
    title: 'groups',
    path: '/groups',
    icon: <TeamOutlined />,
    element: <GroupsPanel />,
    isMainMenu: true,
  },
  {
    title: 'game-types',
    path: '/game-types',
    icon: <DribbbleOutlined />,
    element: <GameTypePanel />,
    isMainMenu: true,
  },
  {
    title: 'locations',
    path: '/locations',
    icon: <EnvironmentOutlined />,
    element: <LocationsPanel />,
    isMainMenu: true,
  },
  {
    title: 'activity-log',
    path: '/activity-log',
    icon: <BarsOutlined />,
    element: <ActivityLog />,
    isMainMenu: true,
  },
];
