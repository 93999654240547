import axios from 'axios';
import { serverUrl } from '../server.constants';
import { NotificationDto } from '../../types/dto.types';

export class NotificationServer {
  private readonly basePath = `${serverUrl}notification`;

  async subscribe() {
    if (Notification.permission === 'default') {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const subscription = await navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration) =>
              registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
              })
            );
          await axios({
            method: 'POST',
            url: `${this.basePath}/subscribe`,
            responseType: 'json',
            data: subscription,
          });
        }
      } catch (error) {}
    }
  }

  async getNotificationsForUser(): Promise<NotificationDto[]> {
    const response = await axios({
      method: 'GET',
      url: this.basePath,
      responseType: 'json',
    });

    return response.data;
  }

  async seeNotifications() {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/see`,
      responseType: 'json',
    });

    return response.data;
  }
}
