import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { GameDto } from '../../types/dto.types';
import { Server } from '../../server/server';
import * as io from 'socket.io-client';
import { wsUrl } from '../../server/server.constants';
import { Divider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/auth-context';

interface BettingProps {
  game: GameDto;
}

const Betting: FC<BettingProps> = (props) => {
  const [results, setResults] = useState({
    firstTeam: 0,
    secondTeam: 0,
    draw: 0,
  });
  const [totalBets, setTotalBets] = useState({});
  const [bidded, setBidded] = useState(false);
  const [expired, setExpired] = useState(false);
  const { admin } = useContext(AuthContext);
  const { t } = useTranslation('betting');

  const didYouBid = useCallback(async () => {
    if (admin) {
      const response = await Server.bidGame.getBidGameByIds(
        admin.id,
        props.game.id
      );

      if (response && response.userChoice) {
        setBidded(true);
      }
    }
  }, [props.game.id, admin]);

  const getResults = useCallback(async () => {
    const result = await Server.bidGame.calculateBidGame(props.game.id);
    if (result) {
      const sum = result.team1 + result.team2 + result.draw;
      setTotalBets(sum);
      if (sum === 0) return;
      setResults((prevState) => ({
        ...prevState,
        firstTeam: parseFloat(((100 * result.team1) / sum).toFixed(1)),
        secondTeam: parseFloat(((100 * result.team2) / sum).toFixed(1)),
        draw: parseFloat(((100 * result.draw) / sum).toFixed(1)),
      }));
    }
  }, [props.game.id]);

  const isExpired = useCallback(async () => {
    if ((new Date().getTime() - Date.parse(props.game.date)) / 1000 / 60 >= 0) {
      setExpired(true);
    }
  }, [props.game.date]);

  useEffect(() => {
    didYouBid();
    getResults();
    isExpired();
    const socket = io.connect(`${wsUrl}`, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: localStorage.getItem('BearerToken'),
          },
        },
      },
    });
    socket.emit('join', '');
    socket.on('refresh', () => {
      didYouBid();
      getResults();
    });
    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
  }, [bidded, props.game, didYouBid, getResults, isExpired]);

  return (
    <>
      <Divider>
        {bidded || expired ? (
          <div className='text-center'>{`${t(
            'total_bets'
          ).toUpperCase()} (${totalBets})`}</div>
        ) : (
          t('betting').toUpperCase()
        )}
      </Divider>
      {bidded || expired ? (
        <div className='mt-1'>
          <div className='mt-1'>
            <Flex justify='space-between'>
              <div className='bet-button'>{`${results.firstTeam}%`}</div>
              <div className='bet-button'>{`${results.draw}%`}</div>
              <div className='bet-button'>{`${results.secondTeam}%`}</div>
            </Flex>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Betting;
