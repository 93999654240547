import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  Divider,
  Flex,
  InputNumber,
  Radio,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { GameStatus } from '../../../../types/enums.types';
import { useTranslation } from 'react-i18next';
import { GameTypeDto } from '../../../../types/dto.types';

interface Props {
  openGameStatus: boolean;
  setOpenGameStatus: Dispatch<SetStateAction<boolean>>;
  openLevel: GameStatus | null | undefined;
  setOpenLevel: Dispatch<SetStateAction<GameStatus | null | undefined>>;
  openGameInfo: {
    players: number;
    goalkeepers: number;
  };
  setOpenGameInfo: Dispatch<
    SetStateAction<{
      players: number;
      goalkeepers: number;
    }>
  >;
  gameType?: GameTypeDto;
}

const PublishingOptions = (props: Props) => {
  const { t } = useTranslation(['games']);

  return (
    <Card className='card-main new-game-options-card'>
      <Divider>{t('new_game.open_games.publishing').toUpperCase()}</Divider>
      <Flex align='center' justify='space-between'>
        <Flex gap={'12px'}>
          <Tooltip title={t('new_game.tips.open')} placement='top'>
            <InfoCircleOutlined />
          </Tooltip>
          <p>{t('new_game.open_games.post')}:</p>
        </Flex>
        <Switch
          onChange={() => {
            props.setOpenGameStatus(!props.openGameStatus);
            props.setOpenGameInfo({
              players: 0,
              goalkeepers: 0,
            });
            props.setOpenLevel(null);
          }}
        />
      </Flex>
      <Divider />
      <Row className='publishing-container'>
        <Col xs={24} md={8} xl={8} xxl={8}>
          <Flex vertical className='publishing-item'>
            <p>{t('new_game.open_games.players_needed')}:</p>
            <InputNumber
              min='0'
              value={props.openGameInfo.players.toString()}
              disabled={!props.openGameStatus}
              onChange={(value) => {
                props.setOpenGameInfo((prevOpenGameInfo) => ({
                  ...prevOpenGameInfo,
                  players: parseInt(value as string) || 0,
                }));
              }}
            />
          </Flex>
        </Col>

        <Col xs={24} md={24} xl={8} xxl={8}>
          <Flex vertical className='publishing-item'>
            <p>{t('new_game.open_games.goalkeepers_needed')}:</p>
            <InputNumber
              min='0'
              max='2'
              value={props.openGameInfo.goalkeepers.toString()}
              disabled={!props.openGameStatus || !props.gameType?.goalkeeper}
              onChange={(value) => {
                props.setOpenGameInfo((prevOpenGameInfo) => ({
                  ...prevOpenGameInfo,
                  goalkeepers: parseInt(value as string) || 0,
                }));
              }}
            />
          </Flex>
        </Col>
        <Col xs={24} md={24} xl={8} xxl={8}>
          <Flex vertical align='center' className='publishing-item'>
            <p>{t('new_game.open_games.post')}: </p>
            <Radio.Group
              disabled={!props.openGameStatus}
              value={props.openLevel}
              onChange={(e) => props.setOpenLevel(e.target.value)}
            >
              <Flex vertical className='publishing-radio'>
                <Radio value={GameStatus.STATUS_OPEN_GLOBAL}>
                  {t('new_game.open_games.global').toUpperCase()}
                </Radio>
                <Radio value={GameStatus.STATUS_OPEN_GROUP}>
                  {t('new_game.open_games.group').toUpperCase()}
                </Radio>
              </Flex>
            </Radio.Group>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

export default PublishingOptions;
