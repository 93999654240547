import { Navigate, Route, Routes } from 'react-router-dom';
import { RoutesDefinitions } from '../constants/routes/routes.constants';
import Profile from '../pages/profile/profile.component';

const PagesRouter = () => {
  return (
    <Routes>
      {RoutesDefinitions.map((x) => {
        return <Route key={x.path} path={x.path} element={x.element} />;
      })}
      <Route path='/users/:id' element={<Profile />} />
      <Route path='*' element={<Navigate to='/games' />} />
    </Routes>
  );
};

export default PagesRouter;
