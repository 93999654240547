import { Col, Card, Skeleton, Flex, Row, Select } from 'antd';
import { FC, useEffect, useState } from 'react';

import { Server } from '../../../server/server';
import { BarChartData, ProfileDto } from '../../../types/dto.types';
import { useTranslation } from 'react-i18next';

import Title from '../../../components/title/title.component';
import BarChartComponent from '../../../components/bar-chart/bar-chart.component';
import { TeamOutlined } from '@ant-design/icons';

interface ActivityProps {
  profile: ProfileDto;
}

const Activity: FC<ActivityProps> = (props) => {
  const [chartData, setChartData] = useState<BarChartData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation('profile');
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    const fetchUserActivity = async () => {
      if (props.profile.userId) {
        const response = await Server.userGame.getActivity(
          props.profile.userId,
          selectedGroupId
        );
        if (response) {
          setChartData(response);
        }
      }
    };

    fetchUserActivity();
    setLoading(false);
  }, [props.profile.userId, selectedGroupId]);

  return (
    <Col xs={24} sm={24} md={24} lg={12}>
      <Card className='card-main h-100'>
        {!loading ? (
          <Flex vertical>
            <Row justify='space-between' gutter={[0, 24]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Title
                  main={t('bar_chart.title')}
                  desc1={t('bar_chart.desc')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Flex align='center' gap={8}>
                  <TeamOutlined />
                  <Select
                    value={selectedGroupId}
                    className='w-100'
                    options={[
                      { value: '', label: t('pie_chart.all_groups') },
                      ...props.profile.groups.map((group) => {
                        return {
                          value: group.groupId,
                          label: group.group.name,
                        };
                      }),
                    ]}
                    onChange={(value) => setSelectedGroupId(value)}
                  />
                </Flex>
              </Col>
            </Row>
            <div className='mt-2'>
              <BarChartComponent data={chartData} unit={t('bar_chart.month')} />
            </div>
          </Flex>
        ) : (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        )}
      </Card>
    </Col>
  );
};

export default Activity;
