import { Col, DatePicker, Flex, Row, Select } from 'antd';
import {
  LogSource,
  LogStatus,
  LogType,
  LogEntityObject,
} from '../../../types/enums.types';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../components/buttons/button.component';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Server } from '../../../server/server';
import { AdminDto, UserDto } from '../../../types/dto.types';

interface Props {
  filter: {
    source?: LogSource;
    status?: LogStatus;
    type?: LogType;
    entity?: LogEntityObject;
    dateFrom: string;
    dateTo: string;
    adminId: string;
    userId: string;
  };
  setFilter: React.Dispatch<
    React.SetStateAction<{
      source?: LogSource;
      status?: LogStatus;
      type?: LogType;
      entity?: LogEntityObject;
      dateFrom: string;
      dateTo: string;
      adminId: string;
      userId: string;
    }>
  >;
}

const ActivityLogFilter = (props: Props) => {
  const { t } = useTranslation('logs');
  const { filter, setFilter } = props;
  const [users, setUsers] = useState<UserDto[]>([]);
  const [admins, setAdmins] = useState<AdminDto[]>([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const usersResponse = await Server.user.getAllUsersFlat();
      setUsers(usersResponse);

      const adminResponse = await Server.admin.getAllAdminsFlat();
      setAdmins(adminResponse);
    };

    fetchOptions();
  }, []);

  const resetFilter = () => {
    setFilter({
      source: undefined,
      status: undefined,
      type: undefined,
      entity: undefined,
      dateFrom: '',
      dateTo: '',
      adminId: '',
      userId: '',
    });
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Row
        className='mt-1'
        gutter={[{ xs: 0, sm: 0, md: 16, lg: 16, xl: 16 }, 16]}
      >
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <Select
            className='w-100'
            value={filter.source ? filter.source : null}
            placeholder={t('role.title')}
            options={Object.values(LogSource).map((source) => ({
              label: t(`role.${source}`),
              value: source,
            }))}
            onChange={(value) =>
              setFilter((prevData) => ({ ...prevData, source: value }))
            }
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <Select
            className='w-100'
            value={filter.status ? filter.status : null}
            placeholder={t('status')}
            options={Object.values(LogStatus).map((status) => ({
              label: status,
              value: status,
            }))}
            onChange={(value) =>
              setFilter((prevData) => ({ ...prevData, status: value }))
            }
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <Select
            className='w-100'
            value={filter.type ? filter.type : null}
            placeholder={t('type.title')}
            options={Object.values(LogType).map((type) => ({
              label: t(`type.${type}`),
              value: type,
            }))}
            onChange={(value) =>
              setFilter((prevData) => ({ ...prevData, type: value }))
            }
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <Select
            className='w-100'
            value={filter.entity ? filter.entity : null}
            placeholder={t('entity.title')}
            options={Object.values(LogEntityObject).map((entity) => ({
              label: t(`entity.${entity}`),
              value: entity,
            }))}
            onChange={(value) =>
              setFilter((prevData) => ({ ...prevData, entity: value }))
            }
          />
        </Col>
      </Row>
      <Row
        className='mt-1'
        gutter={[{ xs: 0, sm: 0, md: 16, lg: 16, xl: 16 }, 16]}
      >
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <DatePicker
            inputReadOnly
            className='w-100'
            placeholder={t('common:from')}
            format={'DD.MM.YYYY.'}
            value={
              filter.dateFrom ? dayjs(filter.dateFrom, 'DD-MM-YYYY') : null
            }
            onChange={(date) => {
              if (date) {
                setFilter((prevFilterData) => ({
                  ...prevFilterData,
                  dateFrom: date.format('DD-MM-YYYY'),
                }));
              }
            }}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <DatePicker
            inputReadOnly
            className='w-100'
            placeholder={t('common:to')}
            format={'DD.MM.YYYY.'}
            value={filter.dateTo ? dayjs(filter.dateTo, 'DD-MM-YYYY') : null}
            onChange={(date) => {
              if (date) {
                setFilter((prevFilterData) => ({
                  ...prevFilterData,
                  dateTo: date.format('DD-MM-YYYY'),
                }));
              }
            }}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <Select
            disabled={filter.source !== LogSource.USER}
            showSearch
            filterOption={filterOption}
            className='w-100'
            options={users.map((user) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName} (${user.email})`,
            }))}
            placeholder={t('role.USER')}
            value={filter.userId ? filter.userId : null}
            onChange={(value) =>
              setFilter((prevData) => ({ ...prevData, userId: value }))
            }
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <Select
            disabled={filter.source !== LogSource.ADMIN}
            showSearch
            filterOption={filterOption}
            className='w-100'
            options={admins.map((admin) => ({
              value: admin.id,
              label: `${admin.firstName} ${admin.lastName} (${admin.email})`,
            }))}
            placeholder={t('role.ADMIN')}
            value={filter.adminId ? filter.adminId : null}
            onChange={(value) =>
              setFilter((prevData) => ({ ...prevData, adminId: value }))
            }
          />
        </Col>
      </Row>
      <Flex justify='end' className='mt-2' gap={'12px'}>
        <ButtonComponent
          className='secondary-button'
          text={t('common:reset_filter')}
          onClick={resetFilter}
        />
      </Flex>
    </>
  );
};

export default ActivityLogFilter;
