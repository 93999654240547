import { LockUserDto, UserLockDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class UserLockServer {
  private readonly basePath = `${serverUrl}admin/user-lock`;

  // GET USER LOCKS
  async getUserLocks(userId: string, groupId: string): Promise<UserLockDto[]> {
    const params = new URLSearchParams();
    params.append('userId', userId.toString());
    params.append('groupId', groupId.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}`,
      responseType: 'json',
      params: params,
    });
    return response.data as UserLockDto[];
  }

  // LOCK USER
  async lockUser(lockUserDto: LockUserDto) {
    const response = await axios({
      method: 'POST',
      url: `${this.basePath}`,
      responseType: 'json',
      data: lockUserDto,
    });
    return response.data;
  }

  // UNLOCK USER
  async unlockUser(userId: string, groupId: string) {
    const params = new URLSearchParams();
    params.append('userId', userId.toString());
    params.append('groupId', groupId.toString());

    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}`,
      responseType: 'json',
      params: params,
    });

    return response.data;
  }

  // CHECK IF USER IS LOCKED
  async checkIfUserIsLocked(userId: string, groupId: string): Promise<boolean> {
    const params = new URLSearchParams();
    params.append('userId', userId);
    params.append('groupId', groupId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/is-locked`,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
