import React, { useEffect, useState } from 'react';
import { GameTypeDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';
import { Collapse } from 'antd';
import Spinner from '../../../components/spinner/spinner.component';
import { useTranslation } from 'react-i18next';
import GameTypeBody from './game-type-body/game-type-body.component';

const GameTypes = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [gameTypes, setGameTypes] = useState<GameTypeDto[]>();
  const [reload, setReload] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    const fetchGameTypes = async () => {
      const response = await Server.gameType.getAllGameTypes();
      setGameTypes(response);
    };

    fetchGameTypes();
    setReload(false);
    setLoading(false);
  }, [reload]);

  return !loading ? (
    <Collapse
      accordion
      style={{ width: '100%' }}
      className='mt-1'
      destroyInactivePanel
      items={gameTypes?.map((gameType) => ({
        key: gameType.id,
        label: <h4>{t(`common:game_types.${gameType.type}`)}</h4>,
        className: 'collapse-main',
        children: <GameTypeBody gameType={gameType} setReload={setReload} />,
      }))}
    />
  ) : (
    <Spinner />
  );
};

export default GameTypes;
