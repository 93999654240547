import { AdminDto, SendInviteDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class AdminServer {
  private readonly basePath = `${serverUrl}admin/admin`;

  // GET ALL ADMINS FLAT
  async getAllAdminsFlat(): Promise<AdminDto[]> {
    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/flat`,
      responseType: 'json',
    });

    return response.data;
  }

  // INVITE ADMIN
  async sendInvite(sendInviteData: SendInviteDto) {
    const response = await axios({
      method: 'POST',
      url: `${this.basePath}/send-invitation`,
      responseType: 'json',
      data: sendInviteData,
    });

    return response.data;
  }
}
