import { Dispatch, SetStateAction, useState } from 'react';
import { GameTypeDto } from '../../../../types/dto.types';
import { Server } from '../../../../server/server';
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Switch,
  notification,
} from 'antd';
import ButtonComponent from '../../../../components/buttons/button.component';
import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { GameTypeCategory } from '../../../../types/enums.types';

interface Props {
  gameType: GameTypeDto;
  setReload: Dispatch<SetStateAction<boolean>>;
}
const GameTypeBody = (props: Props) => {
  const { t } = useTranslation('game-types');
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const initialValues = {
    type: t(`common:game_types.${props.gameType.type}`),
    category: t(`common:game_types.categories.${props.gameType.category}`),
    goalkeeper: props.gameType.goalkeeper,
  };

  const updateGameType = async (values: any) => {
    try {
      await Server.gameType.updateGameType({
        id: props.gameType.id,
        ...values,
      });
      notification.success({ message: t('update_success') });
      props.setReload(true);
    } catch (error) {}
  };

  const deleteGameType = async () => {
    try {
      await Server.gameType.deleteGameType(props.gameType.id);
      notification.success({ message: t('delete_success') });
      props.setReload(true);
    } catch (error) {}
  };

  return (
    <>
      <Flex justify='end' gap={12}>
        <ButtonComponent
          className='secondary-button'
          icon={formDisabled ? <EditOutlined /> : <CloseOutlined />}
          text={formDisabled ? t('common:edit') : t('common:cancel')}
          onClick={() => setFormDisabled(!formDisabled)}
        />
        <Popconfirm
          title={t('delete_confirm')}
          okText={t('common:confirm')}
          cancelText={t('common:cancel')}
          overlayStyle={{
            backgroundColor: 'var(--dark-gray)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: '8px',
          }}
          onConfirm={deleteGameType}
        >
          {' '}
          <ButtonComponent
            className='danger-button'
            icon={<DeleteOutlined />}
            text={t('common:delete')}
          />
        </Popconfirm>
      </Flex>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form
            name='edit-game-type'
            disabled={formDisabled}
            onFinish={updateGameType}
            layout='vertical'
            initialValues={initialValues}
          >
            <Form.Item name='type' label={t('type')}>
              <Input disabled />
            </Form.Item>
            <Form.Item name='category' label={t('category')}>
              <Select
                options={Object.values(GameTypeCategory).map((category) => {
                  return {
                    key: category,
                    label: t(`common:game_types.categories.${category}`),
                    value: category,
                  };
                })}
              />
            </Form.Item>
            <Form.Item name='goalkeeper' label={t('goalkeeper')}>
              <Switch checked={props.gameType.goalkeeper} />
            </Form.Item>
            {!formDisabled ? (
              <Flex justify='end'>
                <Form.Item>
                  <Button className='primary-button' htmlType='submit'>
                    {t('common:confirm')}
                  </Button>
                </Form.Item>
              </Flex>
            ) : (
              <></>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default GameTypeBody;
