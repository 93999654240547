import { LanguageEnum } from '../../types/enums.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class UserSettingsServer {
  private readonly basePath = `${serverUrl}admin/user-settings`;

  // CHANGE LANGUAGE
  async changeLanguage(id: string, language: LanguageEnum) {
    const params = new URLSearchParams();
    params.append('id', id.toString());
    params.append('language', language.toString());

    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/language`,
      responseType: 'json',
      params: params,
    });

    return response.data;
  }
}
