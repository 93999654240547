import Betting from '../../../../components/betting/betting.component';
import Teams from '../../../../components/teams/teams.component';
import { GameDto } from '../../../../types/dto.types';
import { TeamEnum } from '../../../../types/enums.types';

interface GameBodyProps {
  game: GameDto;
}

const GameBody = (props: GameBodyProps) => {
  const playersTeam1 = props.game.userGames.filter(
    (player) => player.team === TeamEnum.team1
  );
  const playersTeam2 = props.game.userGames.filter(
    (player) => player.team === TeamEnum.team2
  );

  return (
    <div>
      <Teams
        playersTeam1={playersTeam1}
        playersTeam2={playersTeam2}
        team1Goals={props.game.team1Goals}
        team2Goals={props.game.team2Goals}
      />
      <Betting game={props.game} />
    </div>
  );
};

export default GameBody;
