import { UserGroupDataDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class UserGroupServer {
  private readonly basePath = `${serverUrl}admin/user-group`;

  // CREATE USER GROUP
  async createUserGroup(newGroup: UserGroupDataDto) {
    const response = await axios({
      method: 'POST',
      url: this.basePath,
      responseType: 'json',
      data: newGroup,
    });

    return response.data;
  }

  // CHANGE ROLE IN USER GROUP
  async changeRole(updatedGroup: UserGroupDataDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/change-role`,
      responseType: 'json',
      data: updatedGroup,
    });

    return response.data;
  }

  // REMOVE USER FROM USER GROUP
  async removeUserFromGroup(userId: string, groupId: string) {
    const params = new URLSearchParams();
    params.append('userId', userId.toString());
    params.append('groupId', groupId.toString());

    const response = await axios({
      method: 'DELETE',
      url: `${this.basePath}/remove-user-from-group`,
      responseType: 'json',
      params: params,
    });

    return response.data;
  }
}
