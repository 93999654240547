import { Select } from 'antd';
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Server } from '../../server/server';
import { SelectOptionsProps } from '../../types/props.types';
import { GameTypeCategory } from '../../types/enums.types';

interface Props {
  className: string;
  value?: string | null;
  style?: CSSProperties;
  setSelectedGameTypeId?: Dispatch<SetStateAction<string>>;
  onChange?: (
    value: any,
    option: SelectOptionsProps | SelectOptionsProps[]
  ) => void;
}

interface GroupedGameTypes {
  [category: string]: {
    value: string;
    label: string;
    title: string;
    options: { value: string; label: string }[];
  };
}

const SelectGameType = (props: Props) => {
  const { t } = useTranslation();

  const [gameTypeOptions, setGameTypeOptions] = useState<
    {
      value: string;
      label: string;
      title: string;
      options: {
        value: string;
        label: string;
      }[];
    }[]
  >();

  useEffect(() => {
    const fetchGameTypes = async () => {
      const response = await Server.gameType.getAllGameTypes();

      const groupedGameTypes: GroupedGameTypes = response.reduce(
        (acc: GroupedGameTypes, gameType: any) => {
          const category = gameType.category;
          if (!acc[category]) {
            acc[category] = {
              value: category,
              label: t(
                `common:game_types.categories.${category}`
              ).toUpperCase(),
              title: category,
              options: [],
            };
          }
          acc[category].options.push({
            value: gameType.id,
            label: t(`common:game_types.${gameType.type}`),
          });
          return acc;
        },
        {}
      );

      const groupedGameTypesArray = Object.values(groupedGameTypes);

      setGameTypeOptions(groupedGameTypesArray);

      if (props.setSelectedGameTypeId && groupedGameTypesArray.length > 0) {
        props.setSelectedGameTypeId(
          groupedGameTypesArray
            .find((cat) => cat.title === GameTypeCategory.FOOTBALL)
            ?.options.find((opt) => opt.label === 'Futsal')?.value ?? ''
        );
      }
    };

    fetchGameTypes();
  }, [props, t]);

  return (
    <Select
      placeholder={t('common:type')}
      className={props.className}
      value={props.value}
      style={props.style}
      options={gameTypeOptions}
      onChange={props.onChange}
    />
  );
};

export default SelectGameType;
