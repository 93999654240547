import { InvitedUserDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class AuthServer {
  private readonly basePath = `${serverUrl}admin/auth`;

  // LOGIN
  async login(email: string, password: string) {
    const body = { email: email, password: password };
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/sign-in`,
        responseType: 'json',
        data: body,
      });
      window.localStorage.setItem('BearerToken', response.data.accessToken);
      return response.data;
    } catch (error) {}
  }

  // SET PASSWORD
  async setPassword(invitedUserDto: InvitedUserDto) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.basePath}/set-password`,
        responseType: 'json',
        data: invitedUserDto,
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }
}
