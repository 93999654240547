import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseJwt } from '../../../utilities/auth/parse-token';
import { InvitedUserDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';

const Invite = () => {
  const params = useParams();
  const navigate = useNavigate();
  const token = params.token;
  const [validUrl, setValidUrl] = useState<boolean>(false);
  const { t } = useTranslation('auth');
  const [loading, setLoading] = useState<boolean>(false);
  const [invitedUserData, setInvitedUserData] = useState<InvitedUserDto>({
    token: '',
    password: '',
  });

  useEffect(() => {
    const parseToken = async () => {
      try {
        const tokenParsed = parseJwt(token!);
        const miliSeconds =
          new Date().getTime() - new Date(tokenParsed.exp * 1000).getTime();
        if (miliSeconds >= 0) {
          setValidUrl(false);
          throw new Error('Expired token');
        }
        setValidUrl(true);
      } catch (err) {
        setValidUrl(false);
      }
    };
    parseToken();
  }, [token]);

  const handleFormValuesChange = (changedValues: any) => {
    setInvitedUserData((prevData) => ({
      ...prevData,
      ...changedValues,
    }));
  };

  const submitData = async () => {
    setLoading(true);
    if (token) {
      invitedUserData.token = token;
      try {
        await Server.auth.setPassword(invitedUserData);
        notification.success({
          message: t('invite.success_msg'),
        });
        navigate('/login');
      } catch (err) {}
    }
    setLoading(false);
  };

  return validUrl ? (
    <Flex justify='center' align='center' className='min-vh-100'>
      <Card className='container login-wrap card-cta-main p-0'>
        <Row gutter={{ xs: 0, sm: 0, md: 16 }}>
          <Col
            xs={24}
            sm={24}
            md={12}
            className='bg-dark-img d-flex align-items-center space-around p-1'
          >
            <div>
              <h3 className='logo-temp text-center m-0'>Balunko admin</h3>
              <p className='text-center'>{t('invite.title')}</p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} className='py-lg-5'>
            <Form
              name='change-credentials'
              className='change-password-form p-1'
              onFinish={submitData}
              onValuesChange={handleFormValuesChange}
            >
              <Divider>{t('invite.set_password')}</Divider>
              <Form.Item
                name='password'
                rules={[
                  { required: true, message: t('field_required') },
                  { min: 8, message: t('pass_invalid') },
                ]}
                validateTrigger='onBlur'
              >
                <Input.Password
                  size='large'
                  className='login-input mt-1'
                  placeholder={t('password')}
                />
              </Form.Item>
              <Form.Item
                name='confirm_password'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t('field_required'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('register.pass_no_match'))
                      );
                    },
                  }),
                ]}
                validateTrigger='onBlur'
              >
                <Input.Password
                  size='large'
                  className='login-input mt-1'
                  placeholder={t('register.confirm_password')}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType='submit'
                  className='primary-button'
                  style={{ width: '100%' }}
                  loading={loading}
                >
                  {t('submit').toUpperCase()}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Flex>
  ) : (
    <Flex justify='center' align='center' className='min-vh-100'>
      <Card className='card-cta-main' style={{ width: '480px' }}>
        <h3 className='me-1'>{t('invalid_url_title')}</h3>
        <p className='gray-color me-1'>{t('invalid_url_text')}</p>
      </Card>
    </Flex>
  );
};

export default Invite;
