import { Card, Col } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { RatingsByTypeDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';
import { useParams } from 'react-router-dom';
import AverageRatings from './average-ratings/average-ratings.component';

const Rating = () => {
  const [ratingsByType, setRatingsByType] = useState<RatingsByTypeDto>({});
  const [reload, setReload] = useState<boolean>(false);
  const params = useParams();
  const userId = useRef<string>(params.id ?? '');

  useEffect(() => {
    const fetchRatings = async () => {
      const response = await Server.rating.getRatings(userId.current);
      setRatingsByType(response);
    };

    fetchRatings();
    setReload(false);
  }, [reload]);

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <Card className='card-cta-secondary h-100 p-0'>
        <AverageRatings ratingsByType={ratingsByType} />
      </Card>
    </Col>
  );
};

export default Rating;
