import {
  Col,
  Flex,
  Input,
  Row,
  Select,
  Table,
  Tooltip,
  notification,
} from 'antd';
import {
  GroupDto,
  UserDto,
  UserGroupDto,
  UsersResponseDto,
} from '../../../../types/dto.types';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Server } from '../../../../server/server';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { setFilterPath } from '../../../../utilities/data/set-filter-path';
import { ColumnsType } from 'antd/es/table';
import GroupOwnerActions from './group-owner-actions/group-owner-actions.component';
import { UserRole } from '../../../../types/enums.types';

interface Props {
  group: GroupDto;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const GroupBody = (props: Props) => {
  const { t } = useTranslation('groups');
  const [editName, setEditName] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const [usersResponse, setUsersResponse] = useState<UsersResponseDto>();
  const [userReload, setUserReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const fetchUsersFromGroup = async () => {
      const response = await Server.user.getAllUsers({
        groupId: props.group.id,
      });
      setUsersResponse(response);
    };

    fetchUsersFromGroup();
    setUserReload(false);
    setSearchParams(setFilterPath({}, page));
    setLoading(false);
  }, [userReload, page, props.group.id, setSearchParams]);

  const updateGroupName = async () => {
    try {
      await Server.group.updateGroup({
        groupId: props.group.id,
        name: newName,
      });
      props.setReload(true);
      notification.success({ message: t('edit_group.name.success') });
    } catch (error) {}
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const updateUserRole = async (userId: string, role: UserRole) => {
    try {
      await Server.userGroup.changeRole({
        groupId: props.group.id,
        userId: userId,
        role: role,
      });

      notification.success({ message: t('edit_group.role.success') });
      setUserReload(true);
    } catch (error) {}
  };

  const columnData: ColumnsType<UserDto> = [
    {
      key: 'name',
      title: t('user_table.user'),
      dataIndex: 'id',
      width: '30%',
      render: (id, record) => (
        <Tooltip title={record.email}>
          <span>{record.firstName + ' ' + record.lastName}</span>
        </Tooltip>
      ),
    },
    {
      key: 'role',
      title: t('user_table.role'),
      dataIndex: 'userGroups',
      width: '20%',
      render: (userGroups, record) => {
        const userRole = record.userGroups.find(
          (ug: UserGroupDto) => ug.groupId === props.group.id
        )?.role;

        return (
          <Select
            className='w-100'
            size='small'
            value={userRole}
            options={Object.values(UserRole).map((option) => {
              return {
                key: option,
                value: option,
                label: t(`common:user_role.${option}`),
              };
            })}
            onChange={(value) => updateUserRole(record.id, value)}
          />
        );
      },
    },
    {
      key: 'admin_actions',
      align: 'end',
      title: t('user_table.options'),
      dataIndex: 'id',
      render: (id, record) => (
        <Flex justify='end'>
          <GroupOwnerActions
            player={record}
            groupId={props.group.id}
            userReload={userReload}
            setUserReload={setUserReload}
          />
        </Flex>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          {!editName ? (
            <Flex align='center' gap={12}>
              <div className='login-input'>{props.group.name}</div>
              <EditOutlined onClick={() => setEditName(true)} />
            </Flex>
          ) : (
            <Flex align='center' gap={12}>
              <Input
                defaultValue={props.group.name}
                autoFocus
                onChange={(e) => setNewName(e.target.value)}
              />
              <CheckOutlined
                onClick={() => {
                  updateGroupName();
                  setEditName(false);
                }}
              />
              <CloseOutlined onClick={() => setEditName(false)} />
            </Flex>
          )}
        </Col>
      </Row>
      <Row>
        <Table
          rowKey='id'
          loading={loading}
          className='mt-2 w-100'
          columns={columnData}
          dataSource={usersResponse?.users}
          pagination={{
            total: usersResponse?.count,
            position: ['bottomCenter'],
            simple: true,
            onChange: handlePageChange,
            current: page,
          }}
          scroll={{ x: 1000 }}
        />
      </Row>
    </div>
  );
};

export default GroupBody;
