import { VoteMvpDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class VoteMvpServer {
  private readonly basePath = `${serverUrl}admin/vote-mvp`;

  // CREATE VOTE MVP
  async createVoteMvp(voteMvp: VoteMvpDto) {
    const response = await axios({
      method: 'POST',
      url: `${this.basePath}`,
      responseType: 'json',
      data: voteMvp,
    });

    return response.data;
  }

  // CALCULATE MVP FOR A GAME
  async calculateMvp(gameId: string) {
    const params = new URLSearchParams();
    params.append('gameId', gameId.toString());

    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/calculate`,
      responseType: 'json',
      params: params,
    });

    return response.data;
  }
}
