import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Divider, Flex } from 'antd';
import {
  CalendarOutlined,
  DribbbleOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import SelectGameType from '../../../../components/select-game-type/select-game-type.component';

import ButtonComponent from '../../../../components/buttons/button.component';
import SelectGroup from '../../../../components/select-group/select-group.component';

interface GamesFilterProps {
  filter: {
    date: string;
    dateTo: string;
    gameTypeId: string;
    groupId: string;
  };
  setFilter: Dispatch<
    SetStateAction<{
      date: string;
      dateTo: string;
      gameTypeId: string;
      groupId: string;
    }>
  >;
}

const GamesFilter = (props: GamesFilterProps) => {
  const { t } = useTranslation('games');
  const { filter, setFilter } = props;

  const resetFilter = () => {
    setFilter({
      date: '',
      dateTo: '',
      gameTypeId: '',
      groupId: '',
    });
  };

  return (
    <>
      <Flex justify='space-around' className='game-filter-container'>
        <div className='mt-1 game-filter-item'>
          <Flex gap={'8px'}>
            <CalendarOutlined style={{ fontSize: '18px' }} />
            <DatePicker
              inputReadOnly
              suffixIcon={<></>}
              format={'DD.MM.YYYY.'}
              placeholder={t('common:from')}
              value={filter.date ? dayjs(filter.date, 'DD-MM-YYYY') : null}
              className='w-100'
              onChange={(date) => {
                if (date) {
                  setFilter((prevFilterData) => ({
                    ...prevFilterData,
                    date: date.format('DD-MM-YYYY'),
                  }));
                }
              }}
            />
            <DatePicker
              inputReadOnly
              suffixIcon={<></>}
              format={'DD.MM.YYYY.'}
              placeholder={t('common:to')}
              value={filter.dateTo ? dayjs(filter.dateTo, 'DD-MM-YYYY') : null}
              className='w-100'
              onChange={(dateTo) => {
                if (dateTo) {
                  setFilter((prevFilterData) => ({
                    ...prevFilterData,
                    dateTo: dateTo.format('DD-MM-YYYY'),
                  }));
                }
              }}
            />
          </Flex>

          <Flex gap={'8px'}>
            <DribbbleOutlined style={{ fontSize: '18px' }} />
            <SelectGameType
              value={filter.gameTypeId ? filter.gameTypeId : null}
              className='mt-1 w-100'
              onChange={(value) => {
                setFilter((prevFilterData) => ({
                  ...prevFilterData,
                  gameTypeId: value,
                }));
              }}
              style={{ width: '100%' }}
            />
          </Flex>
        </div>
        <div className='mt-1 game-filter-item'>
          <Flex gap={'8px'}>
            <TeamOutlined style={{ fontSize: '18px' }} />
            <SelectGroup
              value={filter.groupId ? filter.groupId : null}
              className='w-100'
              onChange={(value) => {
                setFilter((prevFilterData) => ({
                  ...prevFilterData,
                  groupId: value,
                }));
              }}
            />
          </Flex>
        </div>
      </Flex>
      <Flex justify='end' className='mt-2' gap={'12px'}>
        <ButtonComponent
          className='secondary-button'
          text={t('common:reset_filter')}
          onClick={resetFilter}
        />
      </Flex>
      <Divider />
    </>
  );
};

export default GamesFilter;
