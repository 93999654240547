import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/auth/login/login.component';
import { useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import Invite from '../pages/auth/invite/invite.component';

const LoginRouter = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/invitation/:token' element={<Invite />} />
      {!isLoggedIn && <Route path='*' element={<Navigate to='/login' />} />}
    </Routes>
  );
};

export default LoginRouter;
