import { Avatar, Flex } from 'antd';
import { GameDto } from '../../../../types/dto.types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface GameHeaderProps {
  game: GameDto;
}

const GameHeader = (props: GameHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Flex justify='space-between' align='center'>
      <div>
        <span className='bold'>
          {dayjs(props.game.date).format('DD.MM.YYYY.')}{' '}
        </span>
        <span className='gray-color'>
          {dayjs(props.game.date).format('HH:mm')}
        </span>
        <div>
          <i>{t(`common:game_types.${props.game.type}`).toUpperCase()}</i>
        </div>
        <small className='gray-color'>{props.game.groupName}</small>
      </div>
      <div className='d-flex align-items-center space-between'>
        <Avatar
          style={{ backgroundColor: '#000', fontWeight: 'bold' }}
          size={30}
        >
          {props.game.team1Goals}
        </Avatar>
        <h3 style={{ padding: '0 6px' }}>-</h3>
        <Avatar
          style={{
            backgroundColor: '#fff',
            color: '#000',
            fontWeight: 'bold',
          }}
          size={30}
        >
          {props.game.team2Goals}
        </Avatar>
      </div>
    </Flex>
  );
};

export default GameHeader;
