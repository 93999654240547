import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../context/auth-context';
import { Server } from '../../../server/server';
import { Col, Flex, Form, Input, Row, Tooltip, notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ButtonComponent from '../../../components/buttons/button.component';

const NewAdmin = () => {
  const { t } = useTranslation('users');
  const { admin } = useContext(AuthContext);
  const [newAdminData, setNewAdminData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
  }>({
    firstName: '',
    lastName: '',
    email: '',
  });

  const submitNewAdmin = async () => {
    if (admin) {
      const sendInviteData = {
        ...newAdminData,
        adminFirstName: admin.firstName,
        adminLastName: admin.lastName,
      };
      try {
        await Server.admin.sendInvite(sendInviteData);
        setNewAdminData({ firstName: '', lastName: '', email: '' });
        notification.success({ message: t('new_admin.create.success') });
      } catch (error) {}
    }
  };

  const handleFormValuesChange = (changedValues: any) => {
    setNewAdminData((prevNewAdminData) => ({
      ...prevNewAdminData,
      ...changedValues,
    }));
  };
  return (
    <>
      <Flex gap={'12px'} justify='center'>
        <h3>{t('new_admin.create.title').toUpperCase()}</h3>
        <Tooltip title={t('new_admin.create.tip')} placement='right'>
          <InfoCircleOutlined />
        </Tooltip>
      </Flex>
      <Row justify='center' className='mt-2'>
        <Col xs={24} sm={24} md={12} xl={12} xxl={6}>
          <Form
            name='addNewAdmin'
            onFinish={submitNewAdmin}
            onValuesChange={handleFormValuesChange}
          >
            <Form.Item
              name='firstName'
              rules={[
                { required: true, message: t('new_admin.create.required') },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                size='large'
                className='signup-input mt-3'
                placeholder={t('new_admin.create.firstName')}
              />
            </Form.Item>
            <Form.Item
              name='lastName'
              rules={[
                { required: true, message: t('new_admin.create.required') },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                size='large'
                className='signup-input mt-3'
                placeholder={t('new_admin.create.lastName')}
              />
            </Form.Item>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: t('new_admin.create.required') },
                {
                  type: 'email',
                  message: t('new_admin.create.email_invalid'),
                },
              ]}
              validateTrigger='onBlur'
            >
              <Input
                size='large'
                className='signup-input mt-3'
                placeholder={t('new_admin.create.email')}
              />
            </Form.Item>
            <Form.Item>
              <ButtonComponent
                className='primary-button'
                style={{ width: '100%' }}
                text={t('common:confirm')}
                htmlType='submit'
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default NewAdmin;
