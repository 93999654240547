import { Col, Flex, Row, Tooltip, notification } from 'antd';
import ButtonComponent from '../../../../components/buttons/button.component';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Server } from '../../../../server/server';
import { UserRole } from '../../../../types/enums.types';
import ProfileCard from '../../../../components/profile-card/profile-card.component';
import Search, { SearchProps } from 'antd/es/input/Search';
import { useState } from 'react';
import SelectGroup from '../../../../components/select-group/select-group.component';

const AddExistingUser = () => {
  const { t } = useTranslation('users');
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const handleUserSelect = async (id: string) => {
    try {
      if (selectedGroupId) {
        await Server.userGroup.createUserGroup({
          userId: id,
          groupId: selectedGroupId,
          role: UserRole.user,
        });
        notification.success({ message: t('new_user.existing.success') });
      }
    } catch (err) {}
  };

  const onSearch: SearchProps['onSearch'] = async (value) => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

    if (isEmailValid) {
      const response = await Server.user.getUserByEmail(value);
      if (response) {
        if (response.userGroups.some((ug) => ug.groupId === selectedGroupId)) {
          setSelectedUserId('');
          notification.warning({ message: t('new_user.existing.already') });
        } else {
          setSelectedUserId(response.id);
        }
      } else {
        setSelectedUserId('');
      }
    } else {
      notification.warning({ message: t('new_user.existing.invalid_email') });
    }
  };

  return (
    <>
      <Flex gap={'12px'} justify='center'>
        <h3>{t('new_user.existing.title').toUpperCase()}</h3>
        <Tooltip title={t('new_user.existing.tip')} placement='right'>
          <InfoCircleOutlined />
        </Tooltip>
      </Flex>
      <Row justify='center'>
        <Col xs={24} sm={24} md={12} xl={12} xxl={6}>
          <SelectGroup
            value={selectedGroupId ? selectedGroupId : null}
            className='w-100'
            onChange={(value) => setSelectedGroupId(value)}
          />
        </Col>
      </Row>
      <Row justify='center' className='mt-1'>
        <Col xs={24} sm={24} md={12} xl={12} xxl={6}>
          <Flex vertical>
            <Search
              placeholder={t('new_user.existing.email')}
              onSearch={onSearch}
              enterButton
            />
            {selectedUserId ? (
              <>
                <ProfileCard userId={selectedUserId} recentStats={false} />
                <ButtonComponent
                  className='primary-button mt-1'
                  text={t('new_user.submit')}
                  style={{ width: '100%' }}
                  onClick={() => handleUserSelect(selectedUserId)}
                  disabled={!selectedGroupId}
                />
              </>
            ) : (
              <></>
            )}
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default AddExistingUser;
