import {
  CreateGameTypeDto,
  GameTypeDto,
  UpdateGameTypeDto,
} from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';
export class GameTypeServer {
  private readonly basePath = `${serverUrl}admin/game-type`;

  // GET GAME TYPE BY ID
  async getGameTypeById(id: string): Promise<GameTypeDto> {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/type-by-id`,
      responseType: 'json',
      params: params,
    });

    return response.data as GameTypeDto;
  }

  // GET ALL GAME TYPES
  async getAllGameTypes(): Promise<GameTypeDto[]> {
    const response = await axios({
      method: 'GET',
      url: this.basePath,
      responseType: 'json',
    });

    return response.data as GameTypeDto[];
  }

  // CREATE GAME TYPE
  async createGameType(createGameTypeDto: CreateGameTypeDto) {
    const response = await axios({
      method: 'POST',
      url: this.basePath,
      responseType: 'json',
      data: createGameTypeDto,
    });

    return response.data;
  }

  // UPDATE GAME TYPE
  async updateGameType(updateGameTypeDto: UpdateGameTypeDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/update`,
      responseType: 'json',
      data: updateGameTypeDto,
    });

    return response.data;
  }

  // DELETE GAME TYPE
  async deleteGameType(id: string) {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/delete`,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
