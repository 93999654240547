import {
  AddNewPlayerToGroupDto,
  CreateGroupDto,
  GroupDto,
  GroupStatsDto,
  UpdateGroupDto,
} from '../../types/dto.types';
import axios from 'axios';
import { serverUrl } from '../server.constants';

export class GroupServer {
  private readonly basePath = `${serverUrl}admin/group`;

  // GET ALL GROUPS
  async getAllGroups(): Promise<GroupDto[]> {
    const response = await axios({
      method: 'GET',
      url: `${this.basePath}`,
      responseType: 'json',
    });

    return response.data;
  }

  // GET GROUP BY ID
  async getGroupById(id: string): Promise<GroupDto> {
    const params = new URLSearchParams();
    params.append('id', id.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/group-by-id`,
      responseType: 'json',
      params: params,
    });

    return response.data as GroupDto;
  }

  // ADD NEW PLAYER TO GROUP
  async addNewPlayer(newPlayer: AddNewPlayerToGroupDto) {
    const response = await axios({
      method: 'POST',
      url: `${this.basePath}/new-player`,
      responseType: 'json',
      data: newPlayer,
    });
    return response.data;
  }

  // CREATE GROUP
  async createGroup(newGroup: CreateGroupDto) {
    const response = await axios({
      method: 'POST',
      url: `${this.basePath}`,
      responseType: 'json',
      data: newGroup,
    });
    return response.data;
  }

  // UPDATE GROUP (NAME OR GROUP RULES)
  async updateGroup(updatedGroup: UpdateGroupDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/update`,
      responseType: 'json',
      data: updatedGroup,
    });
    return response.data;
  }

  // GET GROUP STATS
  async getGroupStats(id: string): Promise<GroupStatsDto> {
    const params = new URLSearchParams();
    params.append('id', id.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/get-group-stats`,
      responseType: 'json',
      params: params,
    });
    return response.data as GroupStatsDto;
  }
}
