import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Divider, Flex, Switch, Tooltip } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { GameTypeDto, SelectedPlayerDto } from '../../../../types/dto.types';

interface Props {
  mvpVotingStatus: boolean;
  setMvpVotingStatus: Dispatch<SetStateAction<boolean>>;
  players?: SelectedPlayerDto[];
  chooseGoalkeepers?: boolean;
  setChooseGoalkeepers?: Dispatch<SetStateAction<boolean>>;
  resetGoalkeepers?: () => void;
  gameType?: GameTypeDto;
}

const NewGameOptions = (props: Props) => {
  const { t } = useTranslation(['games']);

  return (
    <Card className='card-main new-game-options-card'>
      <Divider>{t('new_game.options').toUpperCase()}</Divider>
      {props.players && props.gameType?.goalkeeper ? (
        <Flex align='center' justify='space-between'>
          <Flex gap={'12px'}>
            <Tooltip title={t('new_game.tips.goalkeeper')} placement='top'>
              <InfoCircleOutlined />
            </Tooltip>
            <p>{t('new_game.goalkeeper')}:</p>
          </Flex>

          <Switch
            disabled={props.players.length < 1}
            onChange={() => {
              if (!props.chooseGoalkeepers) {
                props.setChooseGoalkeepers && props.setChooseGoalkeepers(true);
              } else {
                props.resetGoalkeepers && props.resetGoalkeepers();
                props.setChooseGoalkeepers && props.setChooseGoalkeepers(false);
              }
            }}
          />
        </Flex>
      ) : (
        <></>
      )}
      <Flex align='center' justify='space-between'>
        <Flex gap={'12px'}>
          <Tooltip title={t('new_game.tips.mvp')} placement='top'>
            <InfoCircleOutlined />
          </Tooltip>
          <p>{t('new_game.mvp')}:</p>
        </Flex>
        <Switch
          onChange={() => props.setMvpVotingStatus(!props.mvpVotingStatus)}
        />
      </Flex>
    </Card>
  );
};

export default NewGameOptions;
