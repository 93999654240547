import React, { useEffect, useState } from 'react';
import { SelectOptionsProps } from '../../types/props.types';
import { Server } from '../../server/server';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string | null;
  className?: string;
  onChange?: (
    value: string,
    option: SelectOptionsProps | SelectOptionsProps[]
  ) => void;
}

const SelectGroup = (props: Props) => {
  const { t } = useTranslation();
  const [groupOptions, setGroupOptions] = useState<SelectOptionsProps[]>([]);

  useEffect(() => {
    const fetchGroups = async () => {
      const groupsResponse = await Server.group.getAllGroups();
      const groupOptions = groupsResponse?.map((group) => ({
        value: group.id,
        label: group.name,
      }));

      setGroupOptions(groupOptions);
    };

    fetchGroups();
  }, []);

  return (
    <Select
      value={props.value}
      options={groupOptions}
      className={props.className}
      placeholder={t('common:group')}
      onChange={props.onChange}
    />
  );
};

export default SelectGroup;
