import { Flex, Modal, Popconfirm, Tooltip, notification } from 'antd';
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  CloseOutlined,
  SendOutlined,
  StopOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import {
  LockUserDto,
  ResendInviteDto,
  UserDto,
} from '../../../../../types/dto.types';
import { LockReason, LockType } from '../../../../../types/enums.types';
import { AuthContext } from '../../../../../context/auth-context';
import { Server } from '../../../../../server/server';
import ButtonComponent from '../../../../../components/buttons/button.component';
import LockForm from './lock-form/lock-form.component';

interface GroupOwnerActionsProps {
  groupId: string;
  player: UserDto;
  userReload: boolean;
  setUserReload: Dispatch<SetStateAction<boolean>>;
}

const GroupOwnerActions: FC<GroupOwnerActionsProps> = (props) => {
  const { t } = useTranslation('users');
  const { admin } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [lockData, setLockData] = useState<LockUserDto>({
    userId: props.player.id,
    groupId: props.groupId,
    lockType: LockType.GROUP,
    lockReason: LockReason.OTHER,
    lockDescription: '',
    lockDuration: 7,
  });

  useEffect(() => {
    const checkIfUserIsLocked = async () => {
      const response = await Server.userLock.checkIfUserIsLocked(
        props.player.id,
        props.groupId
      );
      setIsLocked(response);
    };

    checkIfUserIsLocked();
  }, [props.groupId, props.player.id, props.userReload]);

  const resendInvitation = async () => {
    const resendInviteData: ResendInviteDto = {
      id: props.player.id,
      groupId: props.groupId,
      adminFirstName: admin?.firstName,
      adminLastName: admin?.lastName,
    };

    try {
      await Server.user.resendInvite(resendInviteData);
      notification.success({
        message: t('table.group_owner_actions.invite_success'),
      });
    } catch (err) {}
  };

  const lockUser = async () => {
    try {
      await Server.userLock.lockUser(lockData);
      notification.success({
        message: t('table.group_owner_actions.lock_success'),
        description: `${t('table.group_owner_actions.num_of_days')}: ${
          lockData.lockDuration
        }`,
      });
      props.setUserReload(true);
    } catch (err) {}
  };

  const unlockUser = async () => {
    try {
      await Server.userLock.unlockUser(props.player.id, props.groupId);
      notification.success({
        message: t('table.group_owner_actions.unlock_success'),
      });
      props.setUserReload(true);
    } catch (error) {}
  };

  const removeUserFromGroup = async () => {
    try {
      await Server.userGroup.removeUserFromGroup(
        props.player.id,
        props.groupId
      );
      notification.success({
        message: t('table.group_owner_actions.remove_success'),
      });
      props.setUserReload(true);
    } catch (error) {}
  };

  return (
    <Flex gap={12}>
      <Popconfirm
        title={t('table.group_owner_actions.invite_confirm')}
        overlayStyle={{
          backgroundColor: 'var(--dark-gray)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '8px',
        }}
        onConfirm={resendInvitation}
        okText={t('common:confirm')}
        cancelText={t('common:cancel')}
      >
        <Tooltip title={t('table.group_owner_actions.invite')}>
          {' '}
          <div>
            <ButtonComponent
              className='secondary-button'
              icon={<SendOutlined />}
            />
          </div>
        </Tooltip>
      </Popconfirm>
      <Tooltip
        title={
          isLocked
            ? t('table.group_owner_actions.unlock').toUpperCase()
            : t('table.group_owner_actions.lock').toUpperCase()
        }
      >
        <div>
          <ButtonComponent
            className={isLocked ? 'secondary-button' : 'danger-button'}
            icon={isLocked ? <UndoOutlined /> : <StopOutlined />}
            onClick={() => {
              return isLocked ? unlockUser() : setIsModalOpen(true);
            }}
          />
        </div>
      </Tooltip>
      <Popconfirm
        title={t('table.group_owner_actions.remove_confirm')}
        overlayStyle={{
          backgroundColor: 'var(--dark-gray)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '8px',
        }}
        onConfirm={removeUserFromGroup}
        okText={t('common:confirm')}
        cancelText={t('common:cancel')}
      >
        <Tooltip title={t('table.group_owner_actions.remove')}>
          <div>
            <ButtonComponent
              className='danger-button'
              icon={<CloseOutlined />}
            />
          </div>
        </Tooltip>
      </Popconfirm>
      <Modal
        open={isModalOpen}
        footer={null}
        destroyOnClose
        onCancel={() => setIsModalOpen(false)}
        onOk={() => lockUser()}
      >
        <LockForm
          player={props.player}
          setLockData={setLockData}
          setIsModalOpen={setIsModalOpen}
          lockUser={lockUser}
        />
      </Modal>
    </Flex>
  );
};

export default GroupOwnerActions;
