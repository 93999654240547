import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Server } from '../../../server/server';
import { Link, useSearchParams } from 'react-router-dom';
import { UserDto, UserGroupDto } from '../../../types/dto.types';
import { setFilterPath } from '../../../utilities/data/set-filter-path';
import { Table, Tooltip } from 'antd';
import Spinner from '../../../components/spinner/spinner.component';
import { ColumnsType } from 'antd/es/table';

import Title from '../../../components/title/title.component';
import dayjs from 'dayjs';
import Filter from '../../../components/filter/filter.component';
import UsersFilter from './users-filter/users-filter.component';

const Users = () => {
  const { t } = useTranslation('users');
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const [filter, setFilter] = useState<{ groupId: string; name: string }>({
    groupId: searchParams.get('groupId') ?? '',
    name: searchParams.get('name') ?? '',
  });
  const [usersResponse, setUsersResponse] = useState<{
    users: UserDto[];
    count: number;
  }>();

  useEffect(() => {
    setLoading(true);
    const fetchPlayers = async () => {
      const pagination = { take: 10, offset: page - 1 };
      let response = await Server.user.getAllUsers({
        pagination: pagination,
        groupId: filter.groupId,
        name: filter.name,
      });

      setUsersResponse(response);
    };

    fetchPlayers();
    setSearchParams(setFilterPath(filter, page));
    setLoading(false);
  }, [filter, page, setSearchParams]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const columnData: ColumnsType<UserDto> = [
    {
      key: 'name',
      title: t('table.user'),
      dataIndex: 'id',
      render: (id, record) => (
        <Link to={`/users/${record.id}`} style={{ color: 'white' }}>
          <span className='teams-username'>
            {record.firstName + ' ' + record.lastName}
          </span>
        </Link>
      ),
    },
    {
      key: 'email',
      title: t('table.email'),
      dataIndex: 'email',
      render: (email) => <span>{email}</span>,
    },
    {
      key: 'member_since',
      title: t('table.member_since'),
      dataIndex: 'createdAt',
      render: (createdAt) => (
        <span>{dayjs(createdAt).format('DD.MM.YYYY.')}</span>
      ),
    },
  ];

  if (!filter.groupId) {
    columnData.push({
      key: 'groups',
      title: t('table.groups'),
      dataIndex: 'userGroups',
      render: (userGroups) => (
        <Tooltip
          title={userGroups.map((ug: UserGroupDto, index: number) => (
            <p key={index}>{ug.groupName}</p>
          ))}
        >
          <span>{userGroups.length}</span>
        </Tooltip>
      ),
    });
  }

  return !loading ? (
    <div className='mb-2'>
      <Filter
        children={<UsersFilter filter={filter} setFilter={setFilter} />}
        tip={t('filter.tooltip')}
        defaultOpen={false}
      />
      <div className='mt-1'>
        <Title
          main={
            filter.groupId
              ? usersResponse?.users[0].userGroups.find(
                  (ug) => ug.groupId === filter.groupId
                )?.groupName
              : t('all_users')
          }
        />
      </div>
      <Table
        rowKey='id'
        className='players-table mt-1'
        columns={columnData}
        dataSource={usersResponse?.users}
        pagination={{
          total: usersResponse?.count,
          position: ['bottomCenter'],
          simple: true,
          onChange: handlePageChange,
          current: page,
        }}
        scroll={{ x: 1000 }}
      />
    </div>
  ) : (
    <Spinner />
  );
};

export default Users;
