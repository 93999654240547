import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as L from 'leaflet';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import customPin from '../../../../../assets/pin.png';

interface Props {
  position: L.LatLngExpression;
  setCoordinates: Dispatch<SetStateAction<number[]>>;
  formDisabled: boolean;
}

const Map = (props: Props) => {
  const initialPosition = useRef<L.LatLngExpression>(props.position);
  const [clickedPosition, setClickedPosition] = useState<L.LatLngExpression>();

  const Markers = () => {
    useMapEvents({
      click(e) {
        if (!props.formDisabled) {
          setClickedPosition([e.latlng.lat, e.latlng.lng]);
          props.setCoordinates([e.latlng.lat, e.latlng.lng]);
        }
      },
    });

    return (
      <Marker
        position={
          clickedPosition && !props.formDisabled
            ? clickedPosition
            : initialPosition.current
        }
        icon={L.icon({
          iconUrl: customPin,
          iconSize: [36, 36],
          iconAnchor: [16, 40],
        })}
      />
    );
  };

  return (
    <MapContainer
      className='mt-1'
      center={props.position}
      zoom={14}
      scrollWheelZoom={true}
      style={{ height: '50vh', borderRadius: '12px', border: 'none' }}
    >
      <TileLayer
        url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
        maxZoom={100}
      />
      <Markers />
    </MapContainer>
  );
};

export default Map;
