import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateGameTypeDto } from '../../../types/dto.types';
import { GameTypeCategory } from '../../../types/enums.types';
import { Server } from '../../../server/server';
import { Col, Flex, Input, Row, Select, Switch, notification } from 'antd';
import Title from '../../../components/title/title.component';
import ButtonComponent from '../../../components/buttons/button.component';

interface Props {
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const NewGameType = (props: Props) => {
  const { t } = useTranslation('game-types');
  const [newGameType, setNewGameType] = useState<CreateGameTypeDto>({
    type: '',
    category: GameTypeCategory.FOOTBALL,
    goalkeeper: false,
  });

  const createGameType = async () => {
    try {
      await Server.gameType.createGameType(newGameType);
      notification.success({ message: t('create_success') });
      props.setActiveTab('1');
    } catch (error) {}
  };

  return (
    <Row justify='center' className='mt-1'>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Flex justify='center'>
          <Title main={t('new_type.title').toUpperCase()} />
        </Flex>
        <Row className='mt-1'>
          <Input
            placeholder={t('type')}
            autoFocus
            onChange={(e) =>
              setNewGameType((prevData) => ({
                ...prevData,
                type: e.target.value,
              }))
            }
          />
        </Row>
        <Row className='mt-1'>
          <Select
            placeholder={t('category')}
            options={Object.values(GameTypeCategory).map((category) => {
              return {
                key: category,
                label: t(`common:game_types.categories.${category}`),
                value: category,
              };
            })}
            className='w-100'
            onChange={(value) =>
              setNewGameType((prevData) => ({ ...prevData, category: value }))
            }
          />
        </Row>
        <Row className='mt-2'>
          <Flex align='center' gap={12}>
            <span>{t('goalkeeper')}:</span>
            <Switch />
          </Flex>
        </Row>
        <ButtonComponent
          disabled={!newGameType.type}
          className='primary-button mt-2 w-100'
          text={t('common:confirm')}
          onClick={createGameType}
        />
      </Col>
    </Row>
  );
};

export default NewGameType;
