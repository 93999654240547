import { Col, Collapse, Row } from 'antd';
import ChoosePlayers from '../../../../components/choose-players/choose-players.component';
import { TeamEnum } from '../../../../types/enums.types';
import { useTranslation } from 'react-i18next';
import { SelectOptionsProps } from '../../../../types/props.types';
import { SelectedPlayerDto } from '../../../../types/dto.types';
import SelectedPlayersList from '../../../../components/selected-player-list/selected-players-list.component';

interface Props {
  playersTeam1: SelectedPlayerDto[];
  playersTeam2: SelectedPlayerDto[];
  players: SelectedPlayerDto[];
  groupId: string;
  users?: SelectOptionsProps[] | null;
  handleSelectPlayers: (
    selectedOption: SelectOptionsProps,
    team: TeamEnum
  ) => void;
  handleSelectGoalkeepers: (playerId: string, team: TeamEnum) => void;
}

const EditPlayers = (props: Props) => {
  const { t } = useTranslation(['games', 'players']);

  return (
    <>
      <Collapse
        destroyInactivePanel
        items={[
          {
            key: '1',
            label: t('edit_game.edit_players'),
            children: (
              <Row
                className='align-items-center space-between'
                gutter={{ xs: 8, sm: 8, md: 24 }}
              >
                <Col xs={12} sm={12} md={12}>
                  <ChoosePlayers
                    groupId={props.groupId}
                    title={t('common:team1')}
                    options={props.users}
                    team={TeamEnum.team1}
                    teamIds={props.playersTeam1.map((player) => player.id)}
                    otherTeamIds={props.playersTeam2.map((player) => player.id)}
                    handleSelectPlayers={props.handleSelectPlayers}
                  />
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <ChoosePlayers
                    groupId={props.groupId}
                    title={t('common:team2')}
                    options={props.users}
                    team={TeamEnum.team2}
                    teamIds={props.playersTeam2.map((player) => player.id)}
                    otherTeamIds={props.playersTeam1.map((player) => player.id)}
                    handleSelectPlayers={props.handleSelectPlayers}
                    className='text-end'
                  />
                </Col>
              </Row>
            ),
          },
        ]}
      />
      <Row justify='space-between' className='mt-1' gutter={8}>
        <Col xs={12} sm={12} lg={8} xl={6} xxl={4}>
          <SelectedPlayersList
            title={t('new_game.chosen1').toUpperCase()}
            players={props.players}
            team={TeamEnum.team1}
            chooseGoalkeepers={true}
            handleSelectGoalkeepers={props.handleSelectGoalkeepers}
          />
        </Col>
        <Col xs={12} sm={12} lg={8} xl={6} xxl={4}>
          <SelectedPlayersList
            title={t('new_game.chosen2').toUpperCase()}
            players={props.players}
            team={TeamEnum.team2}
            chooseGoalkeepers={true}
            handleSelectGoalkeepers={props.handleSelectGoalkeepers}
          />
        </Col>
      </Row>
    </>
  );
};

export default EditPlayers;
