import { Dispatch, SetStateAction, useState } from 'react';
import { LocationDto } from '../../../../types/dto.types';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Button,
  Col,
  Flex,
  Form,
  Input,
  Popconfirm,
  Row,
  notification,
} from 'antd';
import ButtonComponent from '../../../../components/buttons/button.component';
import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Server } from '../../../../server/server';
import Map from './map/map.component';

interface Props {
  location: LocationDto;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const LocationBody = (props: Props) => {
  const { t } = useTranslation('locations');
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const [coordinates, setCoordinates] = useState<number[]>([
    props.location.latitude ?? 43.5081,
    props.location.longitude ?? 16.4402,
  ]);
  const initialValues = {
    name: props.location.name,
    address: props.location.address,
  };

  const deleteLocation = async () => {
    try {
      await Server.location.deleteLocation(props.location.id);
      notification.success({ message: t('delete_success') });
      props.setReload(true);
    } catch (error) {}
  };

  const updateLocation = async (values: any) => {
    try {
      await Server.location.updateLocation({
        id: props.location.id,
        latitude: coordinates[0],
        longitude: coordinates[1],
        ...values,
      });
      notification.success({ message: t('update_success') });
      props.setReload(true);
    } catch (error) {}
  };

  return (
    <>
      <Flex justify='end' gap={12}>
        <ButtonComponent
          className='secondary-button'
          icon={formDisabled ? <EditOutlined /> : <CloseOutlined />}
          text={formDisabled ? t('common:edit') : t('common:cancel')}
          onClick={() => setFormDisabled(!formDisabled)}
        />
        <Popconfirm
          title={t('delete_confirm')}
          okText={t('common:confirm')}
          cancelText={t('common:cancel')}
          overlayStyle={{
            backgroundColor: 'var(--dark-gray)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: '8px',
          }}
          onConfirm={deleteLocation}
        >
          {' '}
          <ButtonComponent
            className='danger-button'
            icon={<DeleteOutlined />}
            text={t('common:delete')}
          />
        </Popconfirm>
      </Flex>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form
            name='edit-location'
            disabled={formDisabled}
            onFinish={updateLocation}
            layout='vertical'
            initialValues={initialValues}
          >
            <Form.Item name='name' label={t('name')}>
              <Input />
            </Form.Item>
            <Form.Item name='address' label={t('address')}>
              <Input />
            </Form.Item>
            <Form.Item name='coordinates' label={t('coordinates')}>
              {!formDisabled ? (
                <Alert description={t('change_coordinates')} />
              ) : (
                <></>
              )}
              <Map
                formDisabled={formDisabled}
                position={{ lat: coordinates[0], lng: coordinates[1] }}
                setCoordinates={setCoordinates}
              />
            </Form.Item>
            {!formDisabled ? (
              <Flex justify='end'>
                <Form.Item>
                  <Button className='primary-button' htmlType='submit'>
                    {t('common:confirm')}
                  </Button>
                </Form.Item>
              </Flex>
            ) : (
              <></>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default LocationBody;
