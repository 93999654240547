import { useContext, useState } from 'react';
import { Button, Card, Col, Flex, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../context/auth-context';

const Login = () => {
  const { login } = useContext(AuthContext);
  const { t } = useTranslation('auth');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleFormValuesChange = (changedValues: any) => {
    if ('email' in changedValues) {
      setEmail(changedValues.email);
    }
    if ('password' in changedValues) {
      setPassword(changedValues.password);
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    await login(email, password);
    setLoading(false);
  };

  return (
    <Flex justify='center' align='center' className='min-vh-100'>
      <Card className='container login-wrap card-main p-0'>
        <Row gutter={{ xs: 0, sm: 0, md: 16 }}>
          <Col
            xs={24}
            sm={24}
            md={12}
            className='bg-dark-img d-flex align-items-center space-around p-1'
          >
            <div>
              <h3 className='logo-temp m-0'>Balunko Admin</h3>
              <p className='text-small'>balunko.com</p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} className='py-lg-5'>
            <Form
              name='login'
              className='login-form p-1'
              onFinish={() => handleLogin()}
              onValuesChange={handleFormValuesChange}
            >
              <h1 className='m-0 bold text-center'>
                {t('sign_in.sign_in_title')}
              </h1>
              <div className='mt-1'>
                <Form.Item
                  name='email'
                  rules={[
                    { required: true, message: t('field_required') },
                    { type: 'email', message: t('email_invalid') },
                  ]}
                  validateTrigger='onBlur'
                >
                  <Input
                    size='large'
                    className='login-input mt-3'
                    prefix={
                      <div className='login-input-icon input-icon'>
                        <MailOutlined />
                      </div>
                    }
                    placeholder={t('e_mail')}
                  />
                </Form.Item>
                <Form.Item
                  name='password'
                  rules={[
                    { required: true, message: t('field_required') },
                    { min: 8, message: t('pass_invalid') },
                  ]}
                  validateTrigger='onBlur'
                >
                  <Input.Password
                    size='large'
                    className='login-input mt-1'
                    prefix={
                      <div className='login-input-icon input-icon'>
                        <LockOutlined className='site-form-item-icon' />
                      </div>
                    }
                    placeholder={t('password')}
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  htmlType='submit'
                  className='primary-button'
                  style={{ width: '100%' }}
                  loading={loading}
                >
                  {t('sign_in.sign_in_title').toUpperCase()}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Flex>
  );
};

export default Login;
