import { Avatar, Card, Col, Flex, Modal, Popover, Row, Skeleton } from 'antd';

import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import { Level } from '../../../types/enums.types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import LevelsInfo from './levels-info/levels-info.component';

interface ProfileHeaderProps {
  name: string;
  profileImage?: string;
  memberSince: string;
  loading: boolean;
  gamesPlayed: number;
}

const ProfileHeader: FC<ProfileHeaderProps> = (props) => {
  const { t } = useTranslation('profile');

  const [level, setLevel] = useState<{ number: number; level: Level }>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const calculateLevel = () => {
      const { gamesPlayed } = props;

      switch (true) {
        case gamesPlayed < 5:
          setLevel({ number: 1, level: Level.RECRUIT });
          break;
        case gamesPlayed < 15:
          setLevel({ number: 2, level: Level.CADET });
          break;
        case gamesPlayed < 30:
          setLevel({ number: 3, level: Level.SERGEANT });
          break;
        case gamesPlayed < 75:
          setLevel({ number: 4, level: Level.LIEUTENANT });
          break;
        case gamesPlayed < 150:
          setLevel({ number: 5, level: Level.CAPTAIN });
          break;
        case gamesPlayed < 250:
          setLevel({ number: 6, level: Level.MAJOR });
          break;
        case gamesPlayed < 400:
          setLevel({ number: 7, level: Level.COLONEL });
          break;
        default:
          setLevel({ number: 8, level: Level.GENERAL });
      }
    };

    calculateLevel();
  }, [props]);

  return (
    <Row style={{ marginTop: '1rem' }} gutter={16}>
      <Col span={24}>
        <Card className='bg-dark-img card-main user-card'>
          <Flex justify='space-between' align='end'>
            <div className='user-info d-flex align-items-center'>
              {!props.loading ? (
                <div
                  className='me-1'
                  onClick={() => {
                    if (props.profileImage) setIsModalOpen(true);
                  }}
                >
                  <Avatar
                    src={
                      props.profileImage ?? (
                        <h1 className='m-0'>{`${props.name
                          .split(' ')[0]
                          .charAt(0)}${props.name
                          .split(' ')
                          .slice(1)
                          .join(' ')
                          .charAt(0)}`}</h1>
                      )
                    }
                    size={100}
                    className='profile-avatar'
                  />
                </div>
              ) : (
                <div className='me-1'>
                  <Skeleton.Avatar
                    active
                    style={{ width: '100px', height: '100px' }}
                  />
                </div>
              )}
              <Row>
                {!props.loading ? (
                  <Col span={24}>
                    <h1 className='m-0'>{props.name}</h1>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Skeleton.Input active size='large' />
                  </Col>
                )}
                {!props.loading ? (
                  <>
                    <Col span={24}>
                      <p className='text-small text-uppercase  m-0'>
                        {t('header.joined')}:{' '}
                        {dayjs(props.memberSince).format('DD.MM.YYYY.')}
                      </p>
                    </Col>
                    <Col span={24} className='levels-mobile'>
                      <Flex gap={8}>
                        <Popover
                          title={<LevelsInfo level={level} />}
                          placement='bottom'
                          trigger={'click'}
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                        <span className='text-uppercase m-0'>
                          {t('header.level.title')}:{' '}
                          {t(`header.level.${level?.level}`)}
                        </span>
                      </Flex>
                    </Col>
                  </>
                ) : (
                  <Col span={24}>
                    <Skeleton.Input active />
                  </Col>
                )}
              </Row>
            </div>
            <div className='levels-desktop'>
              <LevelsInfo level={level} />
            </div>
          </Flex>
        </Card>
      </Col>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
      >
        <img
          alt='profile-avatar'
          src={props.profileImage}
          className='profile-avatar-large'
        ></img>
      </Modal>
    </Row>
  );
};

export default ProfileHeader;
