import React from 'react';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis } from 'recharts';
import { renderCustomizedLabel } from '../../utilities/data/activity-utils';

interface BarChartProps {
  data: any[];
  unit: string;
  height?: number;
}

const BarChartComponent = ({ data, unit, height }: BarChartProps) => {
  return (
    <ResponsiveContainer width='100%' height={height ?? 400}>
      <BarChart width={150} height={300} data={data} key={Math.random()}>
        <defs>
          <linearGradient
            id='colorUv'
            x1='0'
            y1='0'
            x2='0'
            y2='100%'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0' stopColor='var(--light-blue)' />
            <stop offset='.5' stopColor='var(--blue)' />
            <stop offset='1' stopColor='var(--background-dark)' />
          </linearGradient>
        </defs>
        <Bar
          dataKey='playedGames'
          fill='url(#colorUv)'
          radius={[10, 10, 0, 0]}
          minPointSize={28}
        >
          <LabelList
            dataKey='playedGames'
            position='insideTop'
            content={renderCustomizedLabel}
          />
        </Bar>
        <XAxis
          dataKey='amt'
          stroke='#fff'
          unit={unit}
          axisLine={false}
          tickLine={false}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
