import { BidGameDto, BidGameResultsDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class BidGameServer {
  private readonly basePath = `${serverUrl}admin/bid-game`;

  // GET BID GAME BY IDS
  async getBidGameByIds(userId: string, gameId: string): Promise<BidGameDto> {
    const params = new URLSearchParams();
    params.append('userId', userId.toString());
    params.append('gameId', gameId.toString());

    const response = await axios({
      method: 'GET',
      url: this.basePath,
      responseType: 'json',
      params: params,
    });
    return response.data as BidGameDto;
  }

  // CALCULATE BID GAME RESULTS
  async calculateBidGame(gameId: string): Promise<BidGameResultsDto> {
    const params = new URLSearchParams();
    params.append('gameId', gameId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/calculate`,
      responseType: 'json',
      params: params,
    });

    return response.data as BidGameResultsDto;
  }

  // DELETE BID GAME
  async deleteBidGame(userId: string, gameId: string) {
    const params = new URLSearchParams();
    params.append('gameId', gameId);
    params.append('userId', userId);

    const response = await axios({
      method: 'DELETE',
      url: `${this.basePath}`,
      responseType: 'json',
      params: params,
    });

    return response.data;
  }
}
