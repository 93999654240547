import { Tabs, TabsProps } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Locations from './locations/locations.component';
import NewLocation from './new-location/new-location.component';

const LocationsPanel = () => {
  const { t } = useTranslation('locations');
  const [activeTab, setActiveTab] = useState<string>('1');

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('tabs.locations').toUpperCase(),
      children: <Locations />,
    },
    {
      key: '2',
      label: t('tabs.new_location').toUpperCase(),
      children: <NewLocation setActiveTab={setActiveTab} />,
    },
  ];
  return (
    <Tabs
      onTabClick={(key) => setActiveTab(key)}
      activeKey={activeTab}
      items={tabItems}
      destroyInactiveTabPane={true}
    ></Tabs>
  );
};

export default LocationsPanel;
