import {
  BarChartData,
  CreateUserGameDto,
  PlayerGroupStatsDto,
} from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class UserGameServer {
  private readonly basePath = `${serverUrl}admin/user-game`;

  // UPDATE USER GAME
  async updateUserGame(updatedUserGame: CreateUserGameDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/update`,
      responseType: 'json',
      data: updatedUserGame,
    });

    return response.data;
  }

  // GET ACTIVITY FOR USER
  async getActivity(userId: string, groupId?: string): Promise<BarChartData[]> {
    const params = new URLSearchParams();
    params.append('userId', userId);

    if (groupId) {
      params.append('groupId', groupId);
    }

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/activity`,
      responseType: 'json',
      params: params,
    });

    return response.data as BarChartData[];
  }

  // LEAVE GAME
  async leaveGame(gameId: string) {
    const params = new URLSearchParams();
    params.append('gameId', gameId);

    const response = await axios({
      method: 'DELETE',
      url: this.basePath,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  // GET PLAYER GROUP STATS
  async getPlayerGroupStats(
    groupId: string,
    userId: string
  ): Promise<PlayerGroupStatsDto> {
    const params = new URLSearchParams();
    params.append('userId', userId);
    params.append('groupId', groupId);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/get-player-group-stats`,
      responseType: 'json',
      params: params,
    });
    return response.data as PlayerGroupStatsDto;
  }
}
