import {
  CreateLocationDto,
  GetLocationsDto,
  LocationDto,
  UpdateLocationDto,
} from '../../types/dto.types';

import { serverUrl } from '../server.constants';
import axios from 'axios';

export class LocationServer {
  private readonly basePath = `${serverUrl}admin/location`;

  // GET ALL LOCATIONS
  async getLocations(
    getLocationsDto: GetLocationsDto
  ): Promise<{ locations: LocationDto[]; count: number }> {
    const params = new URLSearchParams();
    if (getLocationsDto.pagination) {
      params.append('take', getLocationsDto.pagination.take.toString());
      params.append('offset', getLocationsDto.pagination.offset.toString());
    }

    const response = await axios({
      method: 'GET',
      url: this.basePath,
      responseType: 'json',
      params,
    });

    return response.data as { locations: LocationDto[]; count: number };
  }

  // GET LOCATION BY ID
  async getLocationById(id: string): Promise<LocationDto> {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/location-by-id`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  // GET LOCATION BY COORDINATES
  async getLocationByCoordinates(
    longitude: number,
    latitude: number
  ): Promise<LocationDto> {
    const params = new URLSearchParams();
    params.append('longitude', longitude.toString());
    params.append('latitude', latitude.toString());

    const response = await axios({
      method: 'GET',
      url: `${this.basePath}/location-by-id`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  // CREATE LOCATION
  async createLocation(createLocationDto: CreateLocationDto) {
    const response = await axios({
      method: 'POST',
      url: `${this.basePath}`,
      responseType: 'json',
      data: createLocationDto,
    });

    return response.data;
  }

  // UPDATE LOCATION
  async updateLocation(updateLocationDto: UpdateLocationDto) {
    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/update`,
      responseType: 'json',
      data: updateLocationDto,
    });

    return response.data;
  }

  // DELETE LOCATION
  async deleteLocation(id: string) {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await axios({
      method: 'PATCH',
      url: `${this.basePath}/delete`,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
