import { useEffect, useState } from 'react';
import { LocationDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';
import { Collapse, Flex, Pagination } from 'antd';
import Spinner from '../../../components/spinner/spinner.component';
import { useSearchParams } from 'react-router-dom';
import { setFilterPath } from '../../../utilities/data/set-filter-path';
import LocationBody from './location-body/location-body.component';

const Locations = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const [locationResponse, setLocationResponse] = useState<{
    locations: LocationDto[];
    count: number;
  }>({ locations: [], count: 0 });
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const fetchLocations = async () => {
      const response = await Server.location.getLocations({});
      setLocationResponse(response);
    };

    fetchLocations();
    setSearchParams(setFilterPath({}, page));
    setReload(false);
    setLoading(false);
  }, [page, reload, setSearchParams]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return !loading ? (
    <>
      <Collapse
        accordion
        style={{ width: '100%' }}
        className='mt-1'
        destroyInactivePanel
        items={locationResponse.locations?.map((location) => ({
          key: location.id,
          label: (
            <Flex align='center' gap={8}>
              <h4>{location.name}</h4>
              <span className='gray-color'>
                <i>{location.address}</i>
              </span>
            </Flex>
          ),
          className: 'collapse-main',
          children: <LocationBody location={location} setReload={setReload} />,
        }))}
      />
      <Flex justify='space-around' className='mt-1'>
        <Pagination
          current={page}
          total={locationResponse?.count}
          pageSize={10}
          onChange={handlePageChange}
          showSizeChanger={false}
          hideOnSinglePage
          simple
        ></Pagination>
      </Flex>
    </>
  ) : (
    <Spinner />
  );
};

export default Locations;
