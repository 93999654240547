import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import Users from './users/users.component';
import NewUser from './new-user/new-user.component';
import NewAdmin from './new-admin/new-admin.component';

const UsersPanel = () => {
  const { t } = useTranslation('users');
  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('tabs.users').toUpperCase(),
      children: <Users />,
    },
    {
      key: '2',
      label: t('tabs.new_user').toUpperCase(),
      children: <NewUser />,
    },
    {
      key: '3',
      label: t('tabs.new_admin').toUpperCase(),
      children: <NewAdmin />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey='1'
      items={tabItems}
      destroyInactiveTabPane={true}
    ></Tabs>
  );
};

export default UsersPanel;
