import { Col, Flex, Tooltip } from 'antd';
import ButtonComponent from '../buttons/button.component';
import { FC, useState } from 'react';
import {
  CloseOutlined,
  FunnelPlotOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface FilterProps {
  children: JSX.Element;
  tip: string;
  defaultOpen: boolean;
}

const Filter: FC<FilterProps> = (props) => {
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(
    props.defaultOpen
  );
  const { t } = useTranslation('open-games');

  return (
    <>
      <Flex gap={'12px'}>
        <ButtonComponent
          className='secondary-button'
          icon={isFilterVisible ? <CloseOutlined /> : <FunnelPlotOutlined />}
          text={isFilterVisible ? t('filter.close') : t('filter.filter')}
          style={{ width: '100px' }}
          onClick={() => setIsFilterVisible(!isFilterVisible)}
        />
        <Tooltip title={props.tip}>
          <QuestionCircleOutlined />
        </Tooltip>
      </Flex>
      <Flex vertical justify='center'>
        {isFilterVisible ? <Col span={24}>{props.children}</Col> : <></>}
      </Flex>
    </>
  );
};

export default Filter;
