import { GetLogsDto, LogDto } from '../../types/dto.types';
import { serverUrl } from '../server.constants';
import axios from 'axios';

export class LogServer {
  private readonly basePath = `${serverUrl}admin/log`;

  async getLogs(
    getLogsDto: GetLogsDto
  ): Promise<{ logs: LogDto[]; count: number }> {
    const { pagination } = getLogsDto;

    const params = new URLSearchParams();
    if (pagination) {
      params.append('take', pagination.take.toString());
      params.append('offset', pagination.offset.toString());
    }

    for (const [key, value] of Object.entries(getLogsDto)) {
      if (key !== 'pagination' && value) {
        params.append(`${key}`, value.toString());
      }
    }

    const response = await axios({
      method: 'GET',
      url: this.basePath,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
