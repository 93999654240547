import { TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Divider, Flex, Input } from 'antd';
import ButtonComponent from '../../../../components/buttons/button.component';
import SelectGroup from '../../../../components/select-group/select-group.component';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  filter: {
    groupId: string;
    name: string;
  };
  setFilter: Dispatch<
    SetStateAction<{
      groupId: string;
      name: string;
    }>
  >;
}

const UsersFilter = (props: Props) => {
  const { filter, setFilter } = props;
  const { t } = useTranslation('users');
  const resetFilter = () => {
    setFilter({
      groupId: '',
      name: '',
    });
  };

  return (
    <>
      <Flex justify='space-around' className='game-filter-container'>
        <div className='mt-1 game-filter-item'>
          <Flex gap={8}>
            <TeamOutlined />
            <SelectGroup
              value={filter.groupId ? filter.groupId : null}
              className='w-100'
              onChange={(value) =>
                setFilter((prevData) => ({ ...prevData, groupId: value }))
              }
            />
          </Flex>
        </div>
        <div className='mt-1 game-filter-item'>
          <Flex gap={'8px'}>
            <UserOutlined />
            <Input
              placeholder={`${t('filter.name')}`}
              value={filter.name ? filter.name : ''}
              onChange={(e) =>
                setFilter((prevFilterData) => ({
                  ...prevFilterData,
                  name: e.target.value,
                }))
              }
            />
          </Flex>
        </div>
      </Flex>
      <Flex justify='end' className='mt-1'>
        <ButtonComponent
          className='secondary-button'
          text={t('common:reset_filter')}
          onClick={resetFilter}
        />
      </Flex>
      <Divider />
    </>
  );
};

export default UsersFilter;
