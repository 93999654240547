import { Tabs, TabsProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Games from './games/games.component';
import NewGame from './new-game/new-game.component';
import EditGames from './edit-games/edit-games.component';

import SelectNewGameGroup from './new-game/select-group/select-new-game-group.component';

const GamesPanel = () => {
  const { t } = useTranslation('games');
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: t('tabs.games').toUpperCase(),
      children: <Games />,
    },
    {
      key: '2',
      label: t('tabs.new_game').toUpperCase(),
      children: selectedGroupId ? (
        <NewGame
          selectedGroupId={selectedGroupId}
          setSelectedGroupId={setSelectedGroupId}
        />
      ) : (
        <SelectNewGameGroup setSelectedGroupId={setSelectedGroupId} />
      ),
    },
    {
      key: '3',
      label: t('tabs.edit_game').toUpperCase(),
      children: <EditGames />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey='1'
      items={tabItems}
      destroyInactiveTabPane={true}
    ></Tabs>
  );
};

export default GamesPanel;
