import { Col, Flex, Input, Row, Select, notification } from 'antd';
import Title from '../../../components/title/title.component';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Server } from '../../../server/server';
import { CreateGroupDto } from '../../../types/dto.types';
import ButtonComponent from '../../../components/buttons/button.component';
import { useTranslation } from 'react-i18next';
import { SelectOptionsProps } from '../../../types/props.types';

interface Props {
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const NewGroup = (props: Props) => {
  const { t } = useTranslation('groups');
  const [newGroupData, setNewGroupData] = useState<CreateGroupDto>({
    group: { name: '' },
    userIds: [],
  });
  const [userOptions, setUserOptions] = useState<SelectOptionsProps[]>();

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await Server.user.getAllUsersFlat();
      const options = response.map((user) => ({
        label: user.firstName + ' ' + user.lastName,
        value: user.id,
      }));

      setUserOptions(options);
    };

    fetchUsers();
  }, []);

  const createGroup = async () => {
    try {
      await Server.group.createGroup(newGroupData);
      notification.success({ message: t('new_group.success') });
      props.setActiveTab('1');
    } catch (error) {}
  };

  return (
    <Row justify='center' className='mt-1'>
      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
        <Flex justify='center'>
          <Title main={t('new_group.title').toUpperCase()} />
        </Flex>
        <Row className='mt-1'>
          <Input
            placeholder={t('new_group.group_name')}
            autoFocus
            onChange={(e) =>
              setNewGroupData((prevData) => ({
                ...prevData,
                group: { name: e.target.value },
              }))
            }
          />
        </Row>
        <Row className='mt-1'>
          <Select
            placeholder={t('new_group.users')}
            mode='multiple'
            options={userOptions}
            className='w-100'
            onChange={(value) =>
              setNewGroupData((prevData) => ({ ...prevData, userIds: value }))
            }
          />
        </Row>
        <ButtonComponent
          disabled={!newGroupData.group.name}
          className='primary-button mt-1 w-100'
          text={t('common:confirm')}
          onClick={createGroup}
        />
      </Col>
    </Row>
  );
};

export default NewGroup;
