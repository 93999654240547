import { Avatar, Card, Flex, Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Level } from '../../../../types/enums.types';

interface Props {
  level?: {
    number: number;
    level: Level;
  };
}

const LevelsInfo = ({ level }: Props) => {
  const { t } = useTranslation('profile');

  const levelsA = [
    {
      title: t('header.level.RECRUIT'),
      range: '0-4',
    },
    {
      title: t('header.level.CADET'),
      range: '5-14',
    },
    {
      title: t('header.level.SERGEANT'),
      range: '15-29',
    },
    {
      title: t('header.level.LIEUTENANT'),
      range: '30-74',
    },
  ];

  const levelsB = [
    {
      title: t('header.level.CAPTAIN'),
      range: '75-149',
    },
    {
      title: t('header.level.MAJOR'),
      range: '150-249',
    },
    {
      title: t('header.level.COLONEL'),
      range: '250-399',
    },
    {
      title: t('header.level.GENERAL'),
      range: '400+',
    },
  ];

  const columns: TableProps<{ title: string; range: string }>['columns'] = [
    {
      dataIndex: 'title',
      key: 'title',
    },
    {
      dataIndex: 'range',
      key: 'range',
      align: 'end',
      className: 'gray-color ',
    },
  ];

  return (
    <Card className='levels-info'>
      <Flex vertical align='center'>
        <Flex gap={10} className='mb-1' align='center'>
          <Avatar
            style={{ background: 'var(--light-blue-transparent)' }}
            shape='square'
            size={56}
            className='text-italic'
          >
            LVL {level?.number}
          </Avatar>
          <Flex vertical>
            <h3 className='m-0 text-uppercase text-italic'>{`${t(
              `header.level.${level?.level}`
            )}`}</h3>
            <p className='m-0 text-uppercase text-small'>
              {t('header.level.by_gamesPlayed')}
            </p>
          </Flex>
        </Flex>
        <Flex gap={24}>
          <Table
            className='mt-1'
            size='small'
            columns={columns}
            dataSource={levelsA}
            pagination={false}
            showHeader={false}
            rowKey='title'
          />
          <Table
            className='mt-1'
            size='small'
            columns={columns}
            dataSource={levelsB}
            pagination={false}
            showHeader={false}
            rowKey='title'
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default LevelsInfo;
