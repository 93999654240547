import { useEffect, useState } from 'react';
import { GroupDto } from '../../../types/dto.types';
import { Server } from '../../../server/server';
import { Collapse } from 'antd';
import GroupBody from './group-body/group-body.component';
import Spinner from '../../../components/spinner/spinner.component';

const Groups = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<GroupDto[]>();
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const fetchGroups = async () => {
      const response = await Server.group.getAllGroups();
      setGroups(response);
    };

    fetchGroups();
    setReload(false);
    setLoading(false);
  }, [reload]);

  return !loading ? (
    <Collapse
      accordion
      style={{ width: '100%' }}
      className='mt-1'
      destroyInactivePanel
      items={groups?.map((group) => ({
        key: group.id,
        label: <h4>{group.name}</h4>,
        className: 'collapse-main',
        children: <GroupBody group={group} setReload={setReload} />,
      }))}
    />
  ) : (
    <Spinner />
  );
};

export default Groups;
