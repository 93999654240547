import { Card, Flex, Avatar, List } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Server } from '../../server/server';
import { calculateProfileCardStats } from '../../utilities/data/calculate-profile-card-stats';
import { ProfileCardDto } from '../../types/dto.types';
import { useTranslation } from 'react-i18next';
import Initials from '../initials/initials.component';

interface ProfileCardProps {
  userId: string;
  recentStats: boolean;
}

const ProfileCard: FC<ProfileCardProps> = (props) => {
  const [profileCardStats, setProfileCardStats] = useState<ProfileCardDto>();
  const { t } = useTranslation('users');

  useEffect(() => {
    const fetchProfile = async () => {
      const response = await Server.user.getProfile(
        props.userId,
        props.recentStats
      );
      if (response) {
        setProfileCardStats(calculateProfileCardStats(response));
      }
    };

    fetchProfile();
  }, [props.recentStats, props.userId]);

  return (
    <Card className='card-cta-secondary mt-1 p-0'>
      <Flex justify='center'>
        {props.recentStats ? (
          <span className='bold text-uppercase'>
            {t('new_user.existing.profile_card.last_month')}
          </span>
        ) : (
          <></>
        )}
      </Flex>
      <Flex align='center' justify='space-around' className='mt-1'>
        <Flex vertical gap={12} align='center' justify='center'>
          {profileCardStats?.profileImage ? (
            <Avatar size={92} src={profileCardStats?.profileImage} />
          ) : (
            <Initials
              firstName={profileCardStats?.name.split(' ')[0] ?? ''}
              lastName={
                profileCardStats?.name.split(' ').slice(1).join(' ') ?? ''
              }
              size={92}
            />
          )}
          <h3 className='m-0 text-center'>{profileCardStats?.name}</h3>
          <span style={{ color: 'var(--gray)' }}>
            {t('new_user.existing.profile_card.member_since')}:{' '}
            {profileCardStats?.memberSince}
          </span>
        </Flex>
        <List size='default' style={{ width: '40%' }}>
          <List.Item>
            <span>{t('new_user.existing.profile_card.games_played')}:</span>
            <b>{profileCardStats?.gamesPlayed}</b>
          </List.Item>
          <List.Item style={{ color: 'var(--green)' }}>
            <span>{t('new_user.existing.profile_card.games_won')}:</span>
            <b>{profileCardStats?.gamesWon}</b>
          </List.Item>
          <List.Item style={{ color: 'var(--red)' }}>
            <span>{t('new_user.existing.profile_card.games_lost')}:</span>
            <b>{profileCardStats?.gamesLost}</b>
          </List.Item>
          <List.Item style={{ color: 'var(--gray)' }}>
            <span>{t('new_user.existing.profile_card.games_draw')}:</span>
            <b>{profileCardStats?.gamesDraw}</b>
          </List.Item>
        </List>
      </Flex>
    </Card>
  );
};

export default ProfileCard;
