import React, { useEffect, useState } from 'react';
import Filter from '../../components/filter/filter.component';
import ActivityLogFilter from './filter/activity-log-filter.component';
import { Link, useSearchParams } from 'react-router-dom';
import { LogDto } from '../../types/dto.types';
import {
  LogEntityObject,
  LogSource,
  LogStatus,
  LogType,
} from '../../types/enums.types';
import { setFilterPath } from '../../utilities/data/set-filter-path';
import { Server } from '../../server/server';
import Spinner from '../../components/spinner/spinner.component';
import { Empty, Flex, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getTagClassName } from '../../utilities/data/get-tag-class-name';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const ActivityLog = () => {
  const { t } = useTranslation('logs');
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [logsResponse, setLogsResponse] = useState<{
    logs: LogDto[];
    count: number;
  }>();
  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const [filter, setFilter] = useState<{
    source?: LogSource;
    status?: LogStatus;
    type?: LogType;
    entity?: LogEntityObject;
    dateFrom: string;
    dateTo: string;
    adminId: string;
    userId: string;
  }>({
    source: (searchParams.get('source') as LogSource) ?? '',
    status: (searchParams.get('status') as LogStatus) ?? '',
    type: (searchParams.get('type') as LogType) ?? '',
    entity: (searchParams.get('entity') as LogEntityObject) ?? '',
    dateFrom: searchParams.get('date') ?? '',
    dateTo: searchParams.get('dateTo') ?? '',
    userId: searchParams.get('userId') ?? '',
    adminId: searchParams.get('adminId') ?? '',
  });

  useEffect(() => {
    const pagination = { take: 10, offset: page - 1 };
    const fetchLogs = async () => {
      setIsLoading(true);
      const response = await Server.log.getLogs({
        pagination: pagination,
        source: filter.source ?? undefined,
        status: filter.status ?? undefined,
        type: filter.type ?? undefined,
        entity: filter.entity ?? undefined,
        dateFrom: filter.dateFrom
          ? dayjs(filter.dateFrom, 'DD-MM-YYYY').toISOString()
          : undefined,
        dateTo: filter.dateTo
          ? dayjs(filter.dateTo, 'DD-MM-YYYY').endOf('day').toISOString()
          : undefined,
        userId: filter.userId ?? undefined,
        adminId: filter.adminId ?? undefined,
      });

      setLogsResponse(response);
      setIsLoading(false);
    };

    fetchLogs();
    setSearchParams(setFilterPath(filter, page));
  }, [filter, page, setSearchParams]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const columnData: ColumnsType<LogDto> = [
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      width: '5%',
      render: (status) => (
        <Tag className={getTagClassName(status)}>{status}</Tag>
      ),
    },
    {
      key: 'source',
      title: t('role.title'),
      dataIndex: 'source',
      width: '5%',
      render: (source) => (
        <Tag className={getTagClassName(source)}>
          {t(`role.${source}`).toUpperCase()}
        </Tag>
      ),
    },
    {
      key: 'name',
      title: t('name'),
      dataIndex: 'source',
      width: '20%',
      render: (source, record) => {
        return source === LogSource.ADMIN ? (
          <Tooltip title={record.adminId}>{record.adminName}</Tooltip>
        ) : (
          <Link to={`/users/${record.userId}`} style={{ color: 'white' }}>
            <span className='teams-username'>{record.userName}</span>
          </Link>
        );
      },
    },

    {
      key: 'type',
      title: t('type.title'),
      dataIndex: 'type',
      width: '5%',
      render: (type) => (
        <Tag className={getTagClassName(type)}>{t(`type.${type}`)}</Tag>
      ),
    },
    {
      key: 'description',
      title: t('description'),
      dataIndex: 'description',
      width: '45%',
      render: (description) => (
        <>
          {description.split('\n').map((line: string, index: number) => (
            <div
              className={index !== 0 ? 'gray-color text-italic' : 'bold'}
              key={index}
            >
              {line}
            </div>
          ))}
        </>
      ),
    },
    {
      key: 'timestamp',
      title: t('timestamp'),
      dataIndex: 'timestamp',
      width: '20%',
      render: (timestamp) => (
        <Flex gap={8}>
          <span>{dayjs(timestamp).format('DD.MM.YYYY.')}</span>
          <span className='gray-color'>{dayjs(timestamp).format('HH:mm')}</span>
        </Flex>
      ),
    },
  ];

  return (
    <div>
      <Filter
        children={<ActivityLogFilter filter={filter} setFilter={setFilter} />}
        defaultOpen={false}
        tip={t('filter_tip')}
      />
      {!isLoading ? (
        <Table
          rowKey='id'
          locale={{
            emptyText: (
              <Empty
                image={<EyeInvisibleOutlined />}
                imageStyle={{ fontSize: '42px' }}
                description={t('common:no_data')}
              />
            ),
          }}
          className='mt-1'
          columns={columnData}
          dataSource={logsResponse?.logs}
          pagination={{
            total: logsResponse?.count,
            position: ['topLeft'],
            simple: true,
            onChange: handlePageChange,
            current: page,
          }}
          scroll={{ x: 1000 }}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ActivityLog;
