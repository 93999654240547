import { Avatar, Col, Flex, List, Row } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';
import { GameDto, UserGameDto } from '../../types/dto.types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Initials from '../initials/initials.component';
import { TeamEnum } from '../../types/enums.types';

interface TeamsProps {
  playersTeam1: UserGameDto[];
  playersTeam2: UserGameDto[];
  team1Goals?: number;
  team2Goals?: number;
  game?: GameDto;
  setReload?: Dispatch<SetStateAction<boolean>>;
}

const Teams: FC<TeamsProps> = (props) => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();

  const resultExists = (team1Goals?: number, team2Goals?: number) => {
    if (!team1Goals || !team2Goals) {
      if (team1Goals === 0 || team2Goals === 0) {
        return true;
      }
      return false;
    }
    return true;
  };

  const renderAvatarAndName = (item: UserGameDto, team: TeamEnum) => {
    return (
      <Flex
        align='center'
        gap={8}
        className={team === TeamEnum.team2 ? 'row-reverse' : ''}
      >
        {item.profileImage ? (
          <Avatar src={item.profileImage} size={32} />
        ) : (
          <Initials
            firstName={item.firstName}
            lastName={item.lastName}
            size={32}
          />
        )}
        <div
          onClick={() => navigate(`/players/${item.userId}`)}
          className={
            team === TeamEnum.team2
              ? 'white-color text-end text-small'
              : 'white-color text-small'
          }
        >
          <div className='d-flex align-items-center teams-username'>
            <div>{item.firstName}&nbsp;</div>
            <div className='first-letter'>{item.lastName}</div>
          </div>
        </div>
      </Flex>
    );
  };

  return (
    <>
      <Row justify='space-between'>
        <h4 className='text-uppercase'>{t('common:team1')}</h4>
        <h4 className='text-end text-uppercase'>{t('common:team2')}</h4>
      </Row>
      <Row
        align={
          resultExists(props.team1Goals, props.team2Goals) ? 'middle' : 'top'
        }
        className='scrollable'
        style={{ border: 'none', borderRadius: '0' }}
      >
        <Col span={resultExists(props.team1Goals, props.team2Goals) ? 8 : 12}>
          <List
            dataSource={props.playersTeam1}
            renderItem={(item, index) => (
              <List.Item key={index} className='justify-start'>
                {renderAvatarAndName(item, TeamEnum.team1)}
              </List.Item>
            )}
          />
        </Col>
        {resultExists(props.team1Goals, props.team2Goals) && (
          <Col span={8}>
            <h1 className='text-center'>
              {props.team1Goals} - {props.team2Goals}
            </h1>
          </Col>
        )}
        <Col span={resultExists(props.team1Goals, props.team2Goals) ? 8 : 12}>
          <List
            dataSource={props.playersTeam2}
            renderItem={(item, index) => (
              <List.Item key={index} className='justify-end'>
                {renderAvatarAndName(item, TeamEnum.team2)}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default Teams;
