import {
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Row,
  Select,
  notification,
} from 'antd';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Server } from '../../../server/server';
import { SelectOptionsProps } from '../../../types/props.types';
import ButtonComponent from '../../../components/buttons/button.component';
import { GameStatus, PlayerRole, TeamEnum } from '../../../types/enums.types';
import ChoosePlayers from '../../../components/choose-players/choose-players.component';
import { useTranslation } from 'react-i18next';
import {
  ArrowLeftOutlined,
  CalendarOutlined,
  DribbbleOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import SelectedPlayersList from '../../../components/selected-player-list/selected-players-list.component';
import { GameTypeDto, SelectedPlayerDto } from '../../../types/dto.types';
import Spinner from '../../../components/spinner/spinner.component';
import Title from '../../../components/title/title.component';
import NewGameOptions from './new-game-options/new-game-options.component';
import PublishingOptions from './publishing-options/publishing-options.component';
import SelectGameType from '../../../components/select-game-type/select-game-type.component';

interface Props {
  selectedGroupId: string;
  setSelectedGroupId: Dispatch<SetStateAction<string>>;
}

const NewGame = (props: Props) => {
  const { t } = useTranslation(['games', 'users']);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<{
    users: SelectOptionsProps[] | null;
    locations: SelectOptionsProps[] | null;
  }>();
  const [date, setDate] = useState<string>();
  const [gameType, setGameType] = useState<GameTypeDto>();
  const [locationId, setLocationId] = useState<string>();
  const [players, setPlayers] = useState<SelectedPlayerDto[]>([]);
  const [chooseGoalkeepers, setChooseGoalkeepers] = useState<boolean>(false);
  const [mvpVotingStatus, setMvpVotingStatus] = useState<boolean>(false);
  const [openGameStatus, setOpenGameStatus] = useState<boolean>(false);
  const [openGameInfo, setOpenGameInfo] = useState({
    players: 0,
    goalkeepers: 0,
  });
  const [openLevel, setOpenLevel] = useState<GameStatus | null>();

  const playersTeam1 = players.filter(
    (player) => player.team === TeamEnum.team1
  );
  const playersTeam2 = players.filter(
    (player) => player.team === TeamEnum.team2
  );

  const fetchGameType = useCallback(async (value: string) => {
    const response = await Server.gameType.getGameTypeById(value);
    setGameType(response);
  }, []);

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true);

      const users = await Server.user.getAllUsers({
        groupId: props.selectedGroupId,
      });

      const locations = await Server.location.getLocations({});
      const userOptions = users?.users.map((user) => {
        return {
          value: user.id,
          label: user.firstName + ' ' + user.lastName,
          profileImage: user.profileImage,
          userLocks: user.userLocks,
        };
      });

      const locationOptions = locations?.locations.map((location) => {
        return { value: location.id, label: location.name };
      });

      setOptions({
        users: userOptions ?? null,
        locations: locationOptions ?? null,
      });
      setIsLoading(false);
    };

    fetchOptions();
  }, [props.selectedGroupId, t]);

  const handleSelectPlayers = (
    selectedOption: SelectOptionsProps,
    team: TeamEnum
  ) => {
    let allTeamIds;
    if (team === TeamEnum.team1) {
      allTeamIds = playersTeam1.map((player) => player.id);
    } else {
      allTeamIds = playersTeam2.map((player) => player.id);
    }

    if (!allTeamIds.includes(selectedOption.value)) {
      const option = {
        id: selectedOption.value,
        name: selectedOption.label,
        team: team,
        role: PlayerRole.player,
        profileImage: selectedOption.profileImage,
      };

      setPlayers([...players, option]);
    } else {
      const newSelectedPlayers = players.filter(
        (player) => player.id !== selectedOption.value
      );
      setPlayers(newSelectedPlayers);
    }
  };

  const resetGoalkeepers = () => {
    const updatedPlayers = players.map((player) => {
      return { ...player, role: PlayerRole.player };
    });
    setPlayers(updatedPlayers);
  };

  const handleSelectGoalkeepers = (playerId: string, team: TeamEnum) => {
    const updatedPlayers = players.map((player) => {
      if (player.id === playerId) {
        return { ...player, role: PlayerRole.goalkeeper };
      } else if (player.team === team) {
        return { ...player, role: PlayerRole.player };
      } else return player;
    });
    setPlayers(updatedPlayers);
  };

  const handleSubmitNewGame = async () => {
    setIsLoading(true);
    if (!gameType?.id || !locationId || !date) {
      notification.warning({ message: t('alerts.fill_all_fields') });
      setIsLoading(false);
    } else {
      if (props.selectedGroupId) {
        const userGames = players?.map((player) => {
          return { userId: player.id, team: player.team, role: player.role };
        });

        try {
          await Server.game.createGame({
            game: {
              date: date!,
              groupId: props.selectedGroupId,
              gameTypeId: gameType.id!,
              locationId: locationId!,
              statusVoting: mvpVotingStatus,
              status: openLevel ? openLevel : GameStatus.STATUS_PENDING,
              openGameInfo: openGameInfo ?? null,
            },
            userGames,
          });
          notification.success({ message: t('new_game.success_create') });
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }
  };

  return !isLoading ? (
    <>
      <Row>
        <ButtonComponent
          className='secondary-button'
          icon={<ArrowLeftOutlined />}
          text={t('common:back')}
          onClick={() => props.setSelectedGroupId('')}
        />
      </Row>
      <Row className='mt-1'>
        <Title main={t('tabs.new_game')} />
      </Row>
      <Row
        gutter={[{ xs: 0, sm: 0, md: 24, xl: 64, xxl: 264 }, 16]}
        className='mt-1'
      >
        <Col xs={24} sm={24} md={24} lg={8} xxl={8}>
          <Flex gap={'8px'}>
            <CalendarOutlined style={{ fontSize: '18px' }} />
            <DatePicker
              inputReadOnly
              suffixIcon={<></>}
              showTime
              className='w-100'
              placeholder={t('new_game.date_and_time')}
              format={'DD.MM.YYYY HH:mm'}
              onChange={(value) => {
                setDate(value?.format('YYYY-MM-DD HH:mm:ss.SSS'));
              }}
            />
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xxl={8}>
          <Flex gap={'8px'}>
            <DribbbleOutlined style={{ fontSize: '18px' }} />
            <SelectGameType
              className='w-100'
              onChange={(value) => fetchGameType(value)}
            />
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xxl={8}>
          {options?.locations && (
            <Flex gap={'8px'}>
              <EnvironmentOutlined style={{ fontSize: '18px' }} />
              <Select
                className='w-100'
                placeholder={t('new_game.location')}
                options={options?.locations}
                onChange={(value) => {
                  setLocationId(value);
                }}
              />
            </Flex>
          )}
        </Col>
      </Row>
      <Card className='card-main mt-1'>
        <Row
          className='align-items-center space-between'
          gutter={{ xs: 12, sm: 24, md: 24 }}
        >
          <Col xs={12} sm={12} md={12}>
            <ChoosePlayers
              title={t('common:team1').toUpperCase()}
              groupId={props.selectedGroupId}
              options={options?.users}
              team={TeamEnum.team1}
              teamIds={playersTeam1.map((player) => player.id)}
              otherTeamIds={playersTeam2.map((player) => player.id)}
              handleSelectPlayers={handleSelectPlayers}
            />
          </Col>
          <Col xs={12} sm={12} md={12}>
            <ChoosePlayers
              title={t('common:team2').toUpperCase()}
              groupId={props.selectedGroupId}
              options={options?.users}
              team={TeamEnum.team2}
              teamIds={playersTeam2.map((player) => player.id)}
              otherTeamIds={playersTeam1.map((player) => player.id)}
              handleSelectPlayers={handleSelectPlayers}
              className='text-end'
            />
          </Col>
        </Row>
      </Card>
      {players.length > 0 ? (
        <>
          <Divider />
          <Row justify='space-between' className='mt-1' gutter={8}>
            <Col xs={12} sm={12} lg={8} xl={6} xxl={4}>
              <SelectedPlayersList
                title={t('new_game.chosen1').toUpperCase()}
                players={playersTeam1}
                team={TeamEnum.team1}
                chooseGoalkeepers={chooseGoalkeepers}
                handleSelectGoalkeepers={handleSelectGoalkeepers}
              />
            </Col>
            <Col xs={12} sm={12} lg={8} xl={6} xxl={4}>
              <SelectedPlayersList
                title={t('new_game.chosen2').toUpperCase()}
                players={playersTeam2}
                team={TeamEnum.team2}
                chooseGoalkeepers={chooseGoalkeepers}
                handleSelectGoalkeepers={handleSelectGoalkeepers}
              />
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
      <Divider />
      <Flex justify='space-between' className='new-game-options-container'>
        <NewGameOptions
          players={players}
          mvpVotingStatus={mvpVotingStatus}
          setMvpVotingStatus={setMvpVotingStatus}
          chooseGoalkeepers={chooseGoalkeepers}
          setChooseGoalkeepers={setChooseGoalkeepers}
          resetGoalkeepers={resetGoalkeepers}
          gameType={gameType}
        />
        <PublishingOptions
          openGameStatus={openGameStatus}
          setOpenGameStatus={setOpenGameStatus}
          openLevel={openLevel}
          setOpenLevel={setOpenLevel}
          openGameInfo={openGameInfo}
          setOpenGameInfo={setOpenGameInfo}
          gameType={gameType}
        />
      </Flex>
      <Flex justify='end'>
        <ButtonComponent
          disabled={
            (openGameStatus && !openLevel) ||
            !date ||
            !gameType?.id ||
            !locationId
          }
          text={t('common:confirm')}
          className='primary-button mt-1'
          onClick={handleSubmitNewGame}
        />
      </Flex>
    </>
  ) : (
    <Spinner />
  );
};

export default NewGame;
