import { Col, Empty, Flex, List, Rate } from 'antd';
import { useTranslation } from 'react-i18next';
import { RatingsByTypeDto } from '../../../../types/dto.types';
import { useRef } from 'react';
import { EyeInvisibleOutlined } from '@ant-design/icons';

interface Props {
  ratingsByType: RatingsByTypeDto;
}

const AverageRatings = ({ ratingsByType }: Props) => {
  const { t } = useTranslation('profile');
  const descriptions = useRef<string[]>([
    t('rating.level.rookie'),
    t('rating.level.rookie'),
    t('rating.level.recreational'),
    t('rating.level.recreational'),
    t('rating.level.recreational'),
    t('rating.level.recreational'),
    t('rating.level.athlete'),
    t('rating.level.athlete'),
    t('rating.level.athlete'),
    t('rating.level.athlete'),
  ]);

  return (
    <Col span={24}>
      <h4 className='m-0'>{t('rating.avg.title')}</h4>
      {Object.entries(ratingsByType).length > 0 ? (
        <List size='small' className='scrollable-medium'>
          {Object.entries(ratingsByType).map(([gameType, rating]) => (
            <List.Item key={gameType}>
              <Flex justify='space-between' className='w-100'>
                <div className='text-uppercase'>
                  {t(`common:game_types.${gameType}`)}
                </div>
                <Flex vertical align='end'>
                  <Rate
                    className='avg-rate-component'
                    value={Number(rating)}
                    count={10}
                    disabled
                  />
                  <span className='text-italic'>
                    {descriptions.current[Number(rating) - 1]}
                  </span>
                </Flex>
              </Flex>
            </List.Item>
          ))}
        </List>
      ) : (
        <Empty
          image={<EyeInvisibleOutlined />}
          imageStyle={{ fontSize: '42px' }}
          description={t('rating.avg.no_data')}
        />
      )}
    </Col>
  );
};

export default AverageRatings;
