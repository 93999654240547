import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GameDto } from '../../../types/dto.types';
import { useTranslation } from 'react-i18next';
import { Server } from '../../../server/server';
import dayjs from 'dayjs';
import { setFilterPath } from '../../../utilities/data/set-filter-path';
import Filter from '../../../components/filter/filter.component';
import { Collapse, Empty, Flex, Pagination } from 'antd';
import Title from '../../../components/title/title.component';
import Spinner from '../../../components/spinner/spinner.component';
import GameHeader from './game-header/game-header.component';
import GameBody from './game-body/game-body.component';
import GamesFilter from './filter/games-filter.component';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const Games = () => {
  const { t } = useTranslation(['games', 'dashboard', 'betting']);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [gamesResponse, setGamesResponse] = useState<{
    games: GameDto[];
    count: number;
  }>();
  const [page, setPage] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const [filter, setFilter] = useState<{
    date: string;
    dateTo: string;
    gameTypeId: string;
    groupId: string;
  }>({
    date: searchParams.get('date') ?? '',
    dateTo: searchParams.get('dateTo') ?? '',
    gameTypeId: searchParams.get('gameTypeId') ?? '',
    groupId: searchParams.get('groupId') ?? '',
  });

  useEffect(() => {
    const pagination = { take: 10, offset: page - 1 };
    const fetchGames = async () => {
      setIsLoading(true);
      const response = await Server.game.getGamesWithFilter({
        pagination: pagination,
        date: filter.date
          ? dayjs(filter.date, 'DD-MM-YYYY').toISOString()
          : undefined,
        dateTo: filter.dateTo
          ? dayjs(filter.dateTo, 'DD-MM-YYYY').endOf('day').toISOString()
          : undefined,
        gameTypeId: filter.gameTypeId,
        groupId: filter.groupId,
      });

      setGamesResponse(response);
      setIsLoading(false);
    };

    fetchGames();
    setSearchParams(setFilterPath(filter, page));
  }, [page, filter, setSearchParams]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <Filter
        children={<GamesFilter filter={filter} setFilter={setFilter} />}
        tip={t('filter.tooltips.filter')}
        defaultOpen={false}
      />

      {!isLoading ? (
        <>
          {gamesResponse && gamesResponse?.count > 0 ? (
            <Flex vertical gap={'6px'} className='mt-1'>
              <Title main={`${gamesResponse?.count} ${t('tabs.games')}`} />
              <Collapse
                accordion
                style={{ width: '100%' }}
                className='mt-1'
                destroyInactivePanel
                items={gamesResponse?.games.map((game) => ({
                  key: game.id,
                  label: <GameHeader game={game} />,
                  className: 'collapse-main',
                  children: <GameBody game={game} />,
                }))}
              />
              <Flex justify='space-around' className='mt-1'>
                <Pagination
                  current={page}
                  total={gamesResponse?.count}
                  pageSize={10}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  hideOnSinglePage
                  simple
                ></Pagination>
              </Flex>
            </Flex>
          ) : (
            <Flex justify='center' align='center' style={{ minHeight: '30vh' }}>
              <Empty
                image={<EyeInvisibleOutlined />}
                imageStyle={{ fontSize: '42px' }}
                description={t('no_data')}
              />
            </Flex>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Games;
